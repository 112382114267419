import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Alert, Box, Button, Divider, Stack, Tooltip, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PIPELINE_SCHEDULE_TRANSLATION } from "../../../constants/commonConstants";
import { InsightContext } from "../../../context/InsightContext";
import { useAxios } from "../../../hooks/useAxios";
import useBoolean from "../../../hooks/useBoolean";
import { SET_SNACKBAR } from "../../../reducers/notificationSnackbarReducer";
import { typography } from "../../../shared/theme-constants";
import BreadCrumbs from "../mui-wrapper-components/BreadCrumb";
import MultiSelect from "../mui-wrapper-components/Select/MultiSelect";
import { Loading } from "../styled-components";
import RootCauseHighChart from "./RootCauseHighChart";
import {
  ASCENDING_SORT_ORDER,
  DESCENDING_SORT_ORDER,
  KPI_POPULATION_CHANGE_PCT,
  KPI_VALUE_MOVEMENT,
  PATTERN_CONTRIBUTION,
  RCA_FILTERING_PTIONS,
} from "./rootcauseConstants";

const RootcauseChart = ({ insightId }) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(RCA_FILTERING_PTIONS[0]);
  const [primaryDriverDropDownOptions, setPrimaryDriversDropDownOptions] = useState([]);
  const [breadCrumbs, setBreadcrumbs] = useState([]);
  const [primaryDriverDropDownValue, setPrimaryDriversDropDownValue] = useState();
  const [topContributor, setTopContributor] = useState(null);
  const [hotspotData, setHotspotData] = useState([]);
  const [drillDownLevel, setDrillDownLevel] = useState(0);
  const [sortOrder, setSortOrder] = useState(DESCENDING_SORT_ORDER);
  const { patternData } = useContext(InsightContext);
  const { data: rootcause, status, error } = useAxios(`insights/${insightId}/rootcause`);
  const [loading, setLoading] = useState(true);
  const [chartLoading, { setTrue: chartLoadingTrue, setFalse: chartLoadingFalse }] =
    useBoolean(false);
  const [nullInfo, setNullInfo] = useState(null);
  const [hoverInfoText, setHoverInfoText] = useState("Nulls omitted");

  const formulateHotspotDetails = useCallback(
    (hotspot) => {
      // sort by filter applied
      let filteredData = [];
      let nullData = hotspot.find((item) => item.dim_val === null);
      filteredData = hotspot
        .filter((data) => data.dim_val !== null)
        .sort((a, b) => {
          if (filter.filterKey === PATTERN_CONTRIBUTION) {
            if (sortOrder === ASCENDING_SORT_ORDER) {
              return Math.abs(a.pattern_contribution) - Math.abs(b.pattern_contribution);
            } else {
              return Math.abs(b.pattern_contribution) - Math.abs(a.pattern_contribution);
            }
          } else if (filter.filterKey === KPI_VALUE_MOVEMENT) {
            if (sortOrder === ASCENDING_SORT_ORDER) {
              return (
                Math.abs(a.kpi_value_movement_decimal) - Math.abs(b.kpi_value_movement_decimal)
              );
            } else {
              return (
                Math.abs(b.kpi_value_movement_decimal) - Math.abs(a.kpi_value_movement_decimal)
              );
            }
          } else {
            if (sortOrder === ASCENDING_SORT_ORDER) {
              return Math.abs(a.kpi_population_change_pct) - Math.abs(b.kpi_population_change_pct);
            } else {
              return Math.abs(b.kpi_population_change_pct) - Math.abs(a.kpi_population_change_pct);
            }
          }
        });

      // Check If Nulls Exists at each drill down level and formulate different text for them
      if (nullData) {
        let hoverInfoText = ``;
        switch (filter.filterKey) {
          case PATTERN_CONTRIBUTION:
            hoverInfoText = `Nulls omitted. ${nullData.formatted_pattern_contribution} ${filter.name}`;
            break;
          case KPI_VALUE_MOVEMENT:
            hoverInfoText = `Nulls omitted. ${nullData.formatted_kpi_value_movement_decimal} ${filter.name}`;
            break;
          case KPI_POPULATION_CHANGE_PCT:
            hoverInfoText = `Nulls omitted. ${nullData.formatted_kpi_population_change_pct} ${filter.name}`;
            break;
        }
        setNullInfo(nullData);
        setHoverInfoText(hoverInfoText);
      } else {
        setNullInfo(null);
      }

      if (filteredData?.length > 0) {
        sortOrder === ASCENDING_SORT_ORDER
          ? setTopContributor(filteredData[filteredData.length - 1])
          : setTopContributor(filteredData[0]);
      }
      setHotspotData([...filteredData]);
    },
    [filter, sortOrder]
  );

  useEffect(() => {
    if (!rootcause) return [];
    // set Primary Driver dropDown options
    setPrimaryDriversDropDownOptions([...rootcause?.rootcause_data[0].rootcause_details]);
    // Set top contributor for the text in Alert Section
    setTopContributor(rootcause?.rootcause_data[0]?.rootcause_details[0]);
  }, [rootcause]);

  useEffect(() => {
    if (!rootcause) return [];
    let rootCauseDetails = [];
    // If it is the primary drivers chart
    // then just get the 0th Elemt from RCA API and distribute its root_cause_details
    // Else Find the drill downs and pass it to formulate the data
    if (drillDownLevel > 0) {
      rootCauseDetails = findRootCauseDetails(breadCrumbs[breadCrumbs.length - 1]);
    } else {
      rootCauseDetails = Object.assign({}, rootcause?.rootcause_data[0]);
    }
    // Formulate the Hotspot chart data for first time render which will be primart drivers
    formulateHotspotDetails([...rootCauseDetails.rootcause_details]);
    setLoading(false);
  }, [filter, sortOrder, rootcause]);

  const handleFilterKeyChange = (_event, newValue) => {
    setFilter(newValue);
  };

  const handleSortOrderChange = () => {
    setSortOrder((prevSortOrder) =>
      prevSortOrder === DESCENDING_SORT_ORDER ? ASCENDING_SORT_ORDER : DESCENDING_SORT_ORDER
    );
  };

  const renderPrimaryDrivers = () => {
    // Set drill down level to 0
    // reset bread crumbs
    // reset the vlau of drop down
    // Reset the null data
    // form the primary drivers chart data
    setNullInfo(null);
    setDrillDownLevel(0);
    setBreadcrumbs([]);
    setPrimaryDriversDropDownValue(null);
    formulateHotspotDetails([...rootcause?.rootcause_data[0].rootcause_details]);
  };

  const findRootCauseDetails = (hotspot) => {
    let matchingRootCauseData = null;
    matchingRootCauseData = rootcause?.rootcause_data?.find((details, index) => {
      return details?.rootcause_details?.find(
        (detail) => hotspot.drill_down_group === detail.group
      );
    });
    return matchingRootCauseData;
  };

  // This Function will check if  drill down actually exists for the drop down value.
  // If yes the we will render the group
  // Else we will render a toast message
  const handleDrillDownOptionChange = (_event, newValue) => {
    let rootCauseDetails = findRootCauseDetails(newValue);

    if (rootCauseDetails) {
      setPrimaryDriversDropDownValue(newValue);
      setBreadcrumbs((breadCrumbs) => [
        { text: newValue.dim_name, value: newValue.dim_val, ...newValue },
      ]);
      formulateHotspotDetails([...rootCauseDetails.rootcause_details]);
    } else {
      // Dispatch a toast message saying No Drill Downs Available
      dispatch({
        type: SET_SNACKBAR,
        snackbarOpen: true,
        snackbarMessage: `Drill down chart is not available for ${newValue.dim_name}: ${newValue.dim_val}`,
        snackbarType: "info",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };

  // Set the Bread Crumbs and Fetch thdrill down group for the same and formulateHotspot details
  const handleBreadCrumbsOptionChange = (newValue) => {
    setBreadcrumbs([...newValue]);
    if (newValue.length > 0) {
      // set the drill down level equal to the length of the bread crumbs
      setDrillDownLevel(newValue.length);
      let rootcause = findRootCauseDetails(newValue[newValue?.length - 1]);
      if (rootcause) {
        formulateHotspotDetails([...rootcause.rootcause_details]);
      }
    }
  };

  // This would be for navigating to and fro for Bread crumbs on RCA
  const handleDrillDown = (_event, hotspot) => {
    let rootCauseDetails = findRootCauseDetails(hotspot);

    // Check if drill down actually exsits
    if (rootCauseDetails) {
      chartLoadingTrue();
      // Increment Drill down level
      formulateHotspotDetails([...rootCauseDetails.rootcause_details]);

      setBreadcrumbs((breadcrumbs) => [
        ...breadcrumbs,
        {
          text: rootCauseDetails?.rootcause_details[0].dim_name,
          value: rootCauseDetails?.rootcause_details[0].dim_val,
          ...hotspot,
        },
      ]);
      setDrillDownLevel((prev) => prev + 1);
      setPrimaryDriversDropDownValue(hotspot);
      chartLoadingFalse();
    } else {
      chartLoadingFalse();
      // Dispatch a toast message saying No Drill Downs Available
      dispatch({
        type: SET_SNACKBAR,
        snackbarOpen: true,
        snackbarMessage: `Drill down chart is not available for ${hotspot.dim_name}: ${hotspot.dim_val}`,
        snackbarType: "info",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };

  const generateTopContributorTextTemplate = () => {
    switch (filter.filterKey) {
      case PATTERN_CONTRIBUTION:
        return <span><b>{topContributor.dim_name} :</b> {topContributor.dim_val} is top contributor, accounts for {topContributor.formatted_pattern_contribution} of total {patternData?.pattern?.formatted_lift_percentage}</span>;
      case KPI_VALUE_MOVEMENT:
        return <span><b>{topContributor.dim_name} :</b> {topContributor.dim_val} had the highest lift, accounting for {topContributor.formatted_kpi_value_movement_decimal} of total</span>;
      case KPI_POPULATION_CHANGE_PCT:
        return <span><b>{topContributor.dim_name} :</b> {
          topContributor.dim_val
        }  had largest volume change of {
          topContributor.formatted_kpi_population_change_pct
        } from previous {PIPELINE_SCHEDULE_TRANSLATION[patternData?.pattern?.pipeline_schedule]}</span>;
      default:
        return ``;
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <Box
      sx={(theme) => ({
        height: "auto",
        border: "1px solid var(--comet)",
        borderRadius: 2,
        margin: "0px 20px",
      })}
    >
      <Stack direction="column" pl={3} pr={3} gap={2}>
        <Stack
          direction="row"
          sx={(theme) => ({ height: theme.typography.pxToRem(65), alignItems: "center" })}
          gap={3}
        >
          <Typography
            sx={(theme) => ({
              fontWeight: typography.font_weight.mediumx,
              fontSize: theme.typography.pxToRem(18),
              color: drillDownLevel > 0 ? "#666666" : "var(--black)",
              ":hover": {
                cursor: `${drillDownLevel > 0 ? "pointer" : "auto"}`,
              },
            })}
            onClick={renderPrimaryDrivers}
          >
            {`Top Contributor By  ${filter.label} ${drillDownLevel > 0 ? ">" : ""}`}
          </Typography>
          {drillDownLevel === 0 ? (
            <Tooltip
              title={`RCA by ${filter.label}: The contribution of each dimension value towards the metric movement (actual vs expected value)`}
              placement="bottom"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    color: "var(--black)",
                    backgroundColor: "#E6E6E6",
                    fontSize: "0.8em",
                  },
                },
              }}
            >
              <InfoOutlinedIcon sx={{ ml: 1.5 }} fontSize="medium" color="var(--comet)" />
            </Tooltip>
          ) : null}

          {drillDownLevel > 1 ? (
            <BreadCrumbs breadCrumbs={breadCrumbs} onChange={handleBreadCrumbsOptionChange} />
          ) : null}
          {drillDownLevel === 1 && (
            <MultiSelect
              placeholder=""
              optionsCheckbox={false}
              disableClearable
              disableCloseOnSelect={false}
              onChange={handleDrillDownOptionChange}
              value={primaryDriverDropDownValue || null}
              options={primaryDriverDropDownOptions || []}
              getOptionLabel={(option) => option.dim_name}
              isOptionEqualToValue={(option, value) => option.dim_val === value.dim_val}
              multiple={false}
              sx={{
                maxWidth: 250,
              }}
            />
          )}
        </Stack>
        <Divider orientation="horizontal" variant="thin" />
        <Alert icon={false} severity="info" sx={(theme) => ({ lineHeight: 3 })}>
          {generateTopContributorTextTemplate()}
        </Alert>
        <Stack direction="row" justifyContent="flex-end" gap={3} pr={5}>
          <MultiSelect
            placeholder=""
            optionsCheckbox={false}
            disableClearable
            disableCloseOnSelect={false}
            onChange={handleFilterKeyChange}
            value={filter}
            options={RCA_FILTERING_PTIONS}
            getOptionLabel={(option) => option.value}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            multiple={false}
          />
          <Button
            onClick={handleSortOrderChange}
            sx={(theme) => ({
              minWidth: 40,
              border: "0.5px solid rgba(0, 0, 0, 0.87)",
              padding: "6px",
              transform: sortOrder === DESCENDING_SORT_ORDER ? "rotate(180deg)" : "rotate(0deg)",
            })}
          >
            <img src={`${process.env.REACT_APP_CDN_BASE_URL}/images/sort_icon.png`} width={10} height={24}></img>
          </Button>
        </Stack>
        {/* Revisit and change following props according to the code : filterType, isPrimaryDriver but they will be needed */}
        {chartLoading ? (
          <Loading />
        ) : (
          <>
            <RootCauseHighChart
              chartData={hotspotData}
              isPrimaryDriverChart={drillDownLevel === 0}
              handleDrillDown={handleDrillDown}
              filterType={filter?.filterKey}
              patternData={patternData}
            />
            {nullInfo && (
              <Stack direction="row" sx={{ alignItems: "center", mt: "10px", mb: "10px" }}>
                <img
                  style={{ width: 25, height: 25, margin: "0 10px 0 0px" }}
                  src={`${process.env.REACT_APP_CDN_BASE_URL}/images/info_icon.png`}
                />
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(12),
                    color: "rgba(102, 102, 102, 1)",
                    fontWeight: typography.font_weight.regular,
                  })}
                >
                  {hoverInfoText}
                </Typography>
              </Stack>
            )}
          </>
        )}
      </Stack>
    </Box>
  );
};

export default RootcauseChart;
