import React from "react";
import { styled as muiStyled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "../../../shared/theme-constants";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${props => props.containerPadding || "20px"};
  margin: 0 auto;
  min-height: 100vh;
  background-color: ${props => props.bgColor || colors.background_color};
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
`;

export const ChartContainer = styled.div`
  width: ${props => props.width || "1035px"};
  max-width: 100%;
  min-width: 400px;
  padding: ${props => props.padding || "0 20px"};
  margin: 0 auto;
  margin-top: ${({ marginTop }) => marginTop};
  transition: margin-top 0.2s ease-out;
`;

export const ChartTitleTypography = muiStyled(
  React.forwardRef(function ChartTitle(props, ref) {
    return <Typography variant="inherit" align="left" {...props} ref={ref} />;
  })
)({
  color: 'var(--comet)',
  fontSize: "18px",
  fontWeight: 600,
});

export const ChartSubtitleTypography = muiStyled(ChartTitleTypography)({
  fontWeight: 400,
  fontSize: "15px",
  marginTop: "2px",
  marginBottom: "30px",
});

export const ShareCardContainer = styled.div`
  margin-top: 35px;
`;
