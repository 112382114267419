import * as React from "react";
import { styled } from "@mui/material/styles";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

const StyledBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
  width: "auto",
  minWidth: "284px",
  borderRadius: 8,
  backgroundColor: "#e0e0e0",
  padding: "2px",
  height: 40,
  [theme.breakpoints.down("tm")]: {
    width: "100%",
  },
}));

const StyledBottomNavigationAction = styled(BottomNavigationAction)(({ theme, selected }) => ({
  borderRadius: 6,
  backgroundColor: selected ? theme.palette.common.white : theme.palette.grey[300],
  "&.Mui-selected": {
    color: "var(--black)",
    fontWeight: 500,
    boxShadow: "0px 1px 2px 0px #00000033",
  },
  "&.Mui-selected:hover": {
    border: "0.5px solid var(--black)",
    backgroundColor: "var(--white)",
  },
  "&:hover": {
    backgroundColor: "rgba(189, 189, 189, 0.4)",
  },
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  minWidth: 0,
  flex: 1,
  padding: "6px 8px", // Adjusted padding to fit within 40px height
  "& .MuiBottomNavigationAction-wrapper": {
    flexDirection: "row", // Ensures icon and label are in the same row
    display: "flex",
    alignItems: "center",
  },
  "& .MuiBottomNavigationAction-label": {
    fontSize: "0.875rem",
    fontWeight: 400,
    display: "inline", // Ensures label is always visible
    "&.Mui-selected": {
      fontWeight: 500,
    },
  },
}));

export default function LabelBottomNavigation({ onValueChange, value, options = [] }) {
  return (
    <StyledBottomNavigation value={value} onChange={onValueChange} showLabels>
      {options.map((item) => (
        <StyledBottomNavigationAction
          key={item.value}
          label={item.label}
          value={item.value}
          icon={item.icon}
          selected={item.value === value}
        />
      ))}
    </StyledBottomNavigation>
  );
}
