import React, { useEffect, useState } from "react";
import HighChartsReactComponent from "../../common/highcharts/HighChartsReactComponent";
import ListLoader from "../../common/ListLoader";
import "./EventDistributionChart.css";

const EventsDistributionChart = ({ chart_data }) => {
  const [chartOptions, setChartOptions] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    generateChartData(chart_data);
  }, []);
  const generateChartOptions = (chartData) => {
    const chartConfig = {
      chart: {
        type: "bar", // Use 'column' for vertical
      },
      title: {
        text: "",
      },
      xAxis: {
        lineWidth: 0,
        categories: [""], // Only one category As we have one one line to show
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        gridLineWidth: 0,
        lineWidth: 0,
        title: {
          text: "",
        },
        labels: {
          enabled: false,
        },
      },
      plotOptions: {
        series: {
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              enabled: false,
              opacity: 1, // Keeps all series fully opaque, preventing fading
            },
          },
          pointPadding: 0.1,
          stacking: "percent",
        },
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.y}</b> ({point.percentage:.1f}%)", // Shows the value and percentage
      },
      legend: {
        navigation: {
          enabled: false,  // Enable pagination for legends
        },
      },
      series: Array.isArray(chartData) ? chartData : [],
    };

    setChartOptions(chartConfig);
    setLoading(false);
  };

  const generateChartData = (chart_data) => {
    let chartData = [];
    let resultArray = [];
    let chartItems = chart_data?.items;
    for (let i = 0; i < chart_data?.items?.length; i++) {
      let temp_obj = {
        name: "",
        data: [],
      };
      temp_obj.name = chartItems[i]?.name;
      temp_obj.data.push(chartItems[i]?.count);

      chartData.push(temp_obj);
    }

    if (chartData?.length > 6) {
      let slicedArray = chartData.slice(5);
      let othersCount = 0;
      slicedArray.map((item) => {
        item?.data.forEach((value) => {
          othersCount += value;
        });
      });
      const startArray = chartData.slice(0, 5);
      resultArray = [...startArray];
      resultArray.push({
        name: "Others",
        data: [othersCount],
      });
    } else {
      resultArray = [...chartData];
    }

    // pass the series info to chart options
    generateChartOptions(resultArray);
  };
  if (loading) return <ListLoader count={1} height={200} paddingY={10} />;
  return (
    <HighChartsReactComponent
      containerProps={{ style: { overflow :"unset", height: 300, maxHeight: 300, width: "100%" } }}
      options={chartOptions}
    />
  );
};

export default EventsDistributionChart;
