import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, CircularProgress, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateTenantDetails } from "../../actions/userOnboardingActions";
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser";
import { SET_SNACKBAR } from "../../reducers/notificationSnackbarReducer";
import { typography } from "../../shared/theme-constants";
import { StyledActionButton } from "../common/mui-wrapper-components/Button";

const TenantCreationScreen = () => {
  const user = useAuthenticatedUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [workspaceName, setWorkspaceName] = useState("");
  const [webstoreUrl, setWebstoreUrl] = useState("");
  const [webstoreUrlError, setWebstoreUrlError] = useState(false);
  const [updatingTenantDetails, setUpdatingTenantDetails] = useState(false);

  const validateURL = (inputUrl) => {
    try {
      new URL(inputUrl); // Check if the URL constructor can parse it
      return true;
    } catch (_) {
      return false;
    }
  };

  // Function to handle form submission
  const handleContinue = () => {
    // Check if the webstore URL is valid
    if (!validateURL(webstoreUrl)) {
      return dispatch({
        type: SET_SNACKBAR,
        snackbarOpen: true,
        snackbarMessage: "Web store url is not valid. Please enter valid URL.",
        snackbarType: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
    setUpdatingTenantDetails(true);
    const postData = {
      display_name: workspaceName,
      store_url: webstoreUrl,
    };
    dispatch(updateTenantDetails(postData, user?.tenant_uuid)).then((response) => {
      setUpdatingTenantDetails(false);
      navigate(`/onboard/join_workspace`);
    });
  };

  const updateWorkspaceName = (event) => {
    const value = event.target.value;
    // Check if the input length exceeds 40 characters
    if (value.length > 40) {
      return dispatch({
        type: SET_SNACKBAR,
        snackbarOpen: true,
        snackbarMessage: "Workspace name cannot exceed 40 characters",
        snackbarType: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
    setWorkspaceName(value);
  };

  const updateWebstoreUrlName = (event) => {
    const value = event.target.value;
    setWebstoreUrl(value);
    setWebstoreUrlError(!validateURL(value));
  };

  return (
    <Box
      elevation={3}
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "auto",
        width: "100vw",
        maxWidth: theme.typography.pxToRem(460),
        boxShadow: "none",
        backgroundColor: "transparent",
        [theme.breakpoints.up("tm")]: {
          width: `${theme.typography.pxToRem(460)} !important`, // Increase height on smaller screens
        },
      })}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20px",
          marginBottom: "20px",
          width: "100%",
        }}
        gap={6}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
            width: "100%",
          }}
          gap={6}
        >
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(22),
              fontWeight: typography.font_weight.mediumx,
              color: "var(--grey-900)",
              textAlign: "center",
            })}
          >
            What would you like to name your
            <br />
            Workspace?
          </Typography>
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(13),
              fontWeight: typography.font_weight.mediumx,
              color: "var(--grey-700)",
              textAlign: "center",
            })}
          >
            Your Workspace is where you can find all your revenue and
            <br />
            marketing metrics.
          </Typography>
        </Box>
        <Paper
          elevation={3}
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "auto",
            width: "100vw",
            maxWidth: theme.typography.pxToRem(460),
            boxShadow: "0 5px 20px 0 rgba(0, 0, 0, 0.15)",
            backgroundColor: "var(--white)",
            padding: "24px",
            borderRadius: "8px",
            gap: "24px",
            [theme.breakpoints.up("tm")]: {
              width: `${theme.typography.pxToRem(460)} !important`, // Increase height on smaller screens
            },
            [theme.breakpoints.down("st")]: {
              width: "100% !important", // Increase height on smaller screens
            },
          })}
        >
          <Stack direction="column" gap={1} sx={{ alignItems: "flex-start" }}>
            <Typography
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(12),
                fontWeight: typography.font_weight.regular,
                color: "var(--grey-900)",
              })}
            >
              Workspace Name
            </Typography>
            <TextField
              type="text"
              sx={(theme) => ({
                width: "100%",
                "& .MuiOutlinedInput-input": {
                  fontSize: theme.typography.pxToRem(13),
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px", // Set border radius for the input field
                  "& fieldset": {
                    border: "1px solid #E0E0E0", // Set border for the input field
                  },
                },
              })}
              size="small"
              value={workspaceName}
              placeholder="Enter workspace name"
              onChange={(event) => updateWorkspaceName(event)}
            />
          </Stack>
          <Stack direction="column" gap={1} sx={{ alignItems: "flex-start" }}>
            <Typography
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(12),
                fontWeight: typography.font_weight.regular,
                color: "var(--grey-700)",
              })}
            >
              Your web-store URL
            </Typography>
            <TextField
              type="text"
              sx={(theme) => ({
                width: "100%",
                "& .MuiOutlinedInput-input": {
                  fontSize: theme.typography.pxToRem(13),
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px", // Set border radius for the input field
                  "& fieldset": {
                    border: "1px solid #E0E0E0", // Set border for the input field
                  },
                },
                "& .MuiFormHelperText-root": {
                  marginLeft: "0px",
                  fontSize: "9px",
                },
              })}
              size="small"
              value={webstoreUrl}
              error={webstoreUrlError}
              placeholder="Please enter store URL"
              onChange={(event) => updateWebstoreUrlName(event)}
              helperText="URL should start from either https:// or http:// (e.g., https://example.com)."
            />
          </Stack>
        </Paper>
        <StyledActionButton
          sx={(theme) => ({
            backgroundColor: "var(--brand-blue)",
            height: theme.typography.pxToRem(46),
            width: theme.typography.pxToRem(124),
            minWidth: "unset",
            fontSize: theme.typography.pxToRem(13),
            "&.Mui-disabled": {
              backgroundColor: "var(--grey-300)", // Customize disabled background color
              color: "var(--grey-50)",
              cursor: "not-allowed",
            },
            "& .MuiButton-endIcon": {
              marginLeft: "4px",
            },
          })}
          onClick={handleContinue}
          endIcon={
            <ArrowForwardIcon
              sx={{
                fontSize: "13px !important",
              }}
            />
          }
          disabled={workspaceName === "" || webstoreUrl === "" || updatingTenantDetails} // When user will click submit that time we will show loader on button and keep the button disable so that user doesn't click again when process is going.
        >
          {/* u2192 gives you -> arrow in html */}
          {updatingTenantDetails ? <CircularProgress size={13} color="inherit" /> : `Continue`}
        </StyledActionButton>
      </Box>
    </Box>
  );
};
export default TenantCreationScreen;
