import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import Container from "@mui/material/Container";
import { ErrorBoundary } from "react-error-boundary";
import { Toaster } from "react-hot-toast";

import "./collaborate/globals.css";
import "./collaborate/styleguide.css";
import userflow from 'userflow.js';

import { useDispatch, useSelector } from "react-redux";
import { getTenantGlobalFilters, getTenantInfo } from "../actions/tenantInfoActions";
import { MASTER_TENANT } from "../constants/tenantConstants";
import { useLocalStorageState } from "../hooks";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import useBoolean from "../hooks/useBoolean";
import { HTTP_STATUS } from "../shared/enums/http-status";
import { errorMessage } from "../shared/intl/error-messages";
import { setFeatureContext } from "../utils/featureFlags";
import { GTAG_EVENTS, sendDataToGTM } from "../utils/gtmHelper";
import ScreenError from "./common/error-components/ScreenError";
import NotificationSnackbar from "./common/mui-wrapper-components/NotificationSnackbar";
import { ModalComponent } from "./common/ui-components/ModalComponent";
import Navbar from "./common/ui-components/Navbar";
import Notification from "./common/ui-components/Notification";

const isDevelopment = process.env.NODE_ENV === "development";

const FallbackComponent = ({ error }) => {
  return (
    <ScreenError title="" message={errorMessage.UNEXPECTED_ERROR} showButtons>
      {isDevelopment ? (
        <Container maxWidth="md" mx="auto">
          <pre style={{ whiteSpace: "pre-wrap" }}>{error.message}</pre>
        </Container>
      ) : null}
    </ScreenError>
  );
};

const HomeLayout = () => {
  const modal = useSelector((state) => state.modal);
  const user = useAuthenticatedUser();
  const [isDrawerOpen, { toggle: toggleDrawer }] = useBoolean(false);
  const dispatch = useDispatch();
  const [selectedTenant] = useLocalStorageState("selectedTenant");
  const [initComplete, setInitComplete] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    // Below is the code to add flows for user using userflow.
    userflow?.init(process.env.REACT_APP_USERFLOW_TOKEN);
    userflow?.identify(user?.user_uuid, {
      name: `${user?.given_name} ${user?.family_name}`,
      email: user?.email,
    });
  }, [user]);

  useEffect(
    function logTenantToGTMWhenUserLoggedIn() {
      if (user) {
        window?.heap?.identify(user.email);
        sendDataToGTM(GTAG_EVENTS.USER_LOGIN_SUCCESS, {
          tenant_id: user.tenant_id,
        });
      }
    },
    [user]
  );

  // fetch the default global filters for the tenant If present
  // This use Effect needs to be above the one which has Tenant info action API call as
  // we want to have this information before we set loading to false
  useEffect(() => {
    let isMounted = true;
    const initApp = async () => {
      if (isMounted) {
        try {
          // We need to await the App till we fetch  and set the default global filters in redux and local storage
          // This is done to correctly propogate the filter info to all the APIS in the application
          await dispatch(getTenantGlobalFilters(selectedTenant));
          setFeatureContext(selectedTenant);
          setInitComplete(true);
        } catch (error) {
          // This is done in order to not block the UI in onboarding flow if tenant filter fails
          setInitComplete(true);
        }
      }
    };

    initApp();

    return () => (isMounted = false);
  }, [dispatch, selectedTenant]);

  useEffect(() => {
    if (initComplete) {
    // Get the state of the application
    const globalFiltersInLocalStorage = window.localStorage.getItem("currentAppliedGlobalFilters");
    let globalFilters = JSON.parse(globalFiltersInLocalStorage);
      if (user?.show_onboarding) {
        if (user?.tenant_id === MASTER_TENANT) {
          dispatch(getTenantInfo(selectedTenant, globalFilters))
            .then((response) => {
              setLoading(false);
            })
            .catch((error) => {
              // Check if the error is 401
              if (error.response.status === HTTP_STATUS.UNAUTHORIZED) {
                setLoading(false);
                // Redirect to login page
                navigate("/login");
              }
            });
        } else {
          setLoading(false);
        }
      } else {
        dispatch(
          getTenantInfo(
            user?.tenant_id === MASTER_TENANT ? selectedTenant : user?.tenant_id,
            globalFilters
          )
        )
          .then((response) => {
            setLoading(false);
          })
          .catch((error) => {
            // Check if the error is 401
            if (error.response.status === HTTP_STATUS.UNAUTHORIZED) {
              setLoading(false);
              // Redirect to login page
              navigate("/login");
            }
          });
      }
    }
  }, [initComplete]);

  return (
    <>
      <div className="app-container">
        <Notification />
        <NotificationSnackbar />
        <ModalComponent
          modalName={modal.modalName}
          modalProps={modal.modalProps}
          showCloseButton={modal.showCloseButton}
        />
        <Toaster position="top-center" />
        <Navbar drawerOpen={toggleDrawer} />
        <main style={{ marginTop: "var(--navbar-height)" }}>
          <ErrorBoundary FallbackComponent={FallbackComponent}>
            {!loading && <Outlet context={{ isDrawerOpen }}></Outlet>}
          </ErrorBoundary>
        </main>
      </div>
    </>
  );
};

export default HomeLayout;
