import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Card, CardContent, CardHeader, Stack, Tooltip, Typography } from "@mui/material";
import { eventHealthandColor, eventHealthandIcon } from "../../constants/commonConstants";
import { typography } from "../../shared/theme-constants";

const ReportsList = ({ providersData = [] }) => {
  return (
    <Box
      sx={(theme) => ({
        padding: "48px 150px",
        paddingTop: "0px",
        marginTop: "150px",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        rowGap: "18px",
        columnGap: "48px",
        [theme.breakpoints.down("tm")]: {
          padding: "48px 50px",
          paddingTop: "0px",
          marginTop: "230px",
        },
        [theme.breakpoints.down("lt")]: {
          marginTop: "230px",
        },
      })}
    >
      {providersData.map((item, index) => (
        <Card
          sx={(theme) => ({
            width: 372,
            border: "0.5px solid #EBEBEB",
            boxShadow: "unset",
            [theme.breakpoints.down("sl")]: {
              width: "100%",
            },
          })}
          key={item.provider_uuid}
        >
          <CardHeader
            sx={{ borderBottom: "0.5px solid #EBEBEB" }}
            title={
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Stack flexDirection="column" gap={3} alignItems="center">
                  <Stack flexDirection="row" alignItems="center" gap={2.5} sx={{ alignSelf: "flex-start" }}>
                    <img
                      style={{ width: "20px", height: "20px", objectFit: "contain" }}
                      src={item.provider_logo_url}
                    />
                    <Typography
                      sx={(theme) => ({
                        fontSize: theme.typography.pxToRem(16),
                        fontWeight: typography.font_weight.mediumx,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "1",
                        WebkitBoxOrient: "vertical",
                        color: "var(--black)",
                        opacity: 0.87,
                      })}
                    >
                      {item.provider_display_name}
                    </Typography>
                    <Tooltip
                      title={`List of checks that are being done for ${item.provider_display_name}`}
                      placement="bottom"
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            color: "var(--black)",
                            backgroundColor: "#E6E6E6",
                            fontSize: "0.7em",
                          },
                        },
                      }}
                    >
                      <InfoOutlinedIcon
                        sx={{ width: "14px", height: "14px" }}
                        fontSize="small"
                        color="#00000099"
                      />
                    </Tooltip>
                  </Stack>
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(14),
                      fontWeight: typography.font_weight.regular,
                      color: "var(--grey-700)",
                    })}
                  >
                    {`${item.checks?.completed} of ${item.checks?.total} checks done`}
                  </Typography>
                </Stack>
                <Box
                  sx={(theme) => ({
                    borderRadius: "100px",
                    backgroundColor: eventHealthandColor[item.health],
                    padding: "5px 10px",
                    width: "87px",
                    height: "28px",
                  })}
                >
                  <Stack flexDirection="row" alignItems="center" justifyContent="center" gap={1.5}>
                    <img
                      src={`${process.env.REACT_APP_CDN_BASE_URL}/images/${eventHealthandIcon[item.health]}`}
                      style={{ width: "14px", height: "14px", objectFit: "contain" }}
                    />
                    <Typography
                      sx={(theme) => ({
                        fontSize: theme.typography.pxToRem(12),
                        fontWeight: typography.font_weight.regular,
                        color: "var(--grey-800)",
                      })}
                    >
                      {item.health_display_name}
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            }
          />
          <CardContent
            sx={{
              padding: 0,
              "&:last-child": { paddingBottom: 0 },
              maxHeight: index <= 2 ? "136.5px" : "273px",
              overflow: "scroll",
            }}
          >
            {item.checks?.statuses.map((check, index) => (
              <Stack
                key={check.provider_check_uuid}
                flexDirection="row"
                alignItems="center"
                sx={{ width: "100%", borderBottom: "0.5px solid #EBEBEB" }}
              >
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  gap={1}
                  sx={{ width: "70%", padding: "12px 20px", borderRight: "0.5px solid #EBEBEB" }}
                >
                  <img
                    src={`${process.env.REACT_APP_CDN_BASE_URL}/images/text_check.png`}
                    style={{ width: "18px", height: "18px", objectFit: "contain" }}
                  />
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(14),
                      fontWeight: typography.font_weight.regular,
                      color: "var(--black)",
                      opacity: 0.87,
                    })}
                  >
                    {check.provider_check_display_name}
                  </Typography>
                </Stack>
                <Stack
                  flexDirection="row"
                  gap={1.5}
                  alignItems="center"
                  justifyContent="center"
                  sx={{ width: "30%", padding: "12px 20px" }}
                >
                  <img
                    src={`${process.env.REACT_APP_CDN_BASE_URL}/images/${eventHealthandIcon[check.health]}`}
                    style={{ width: "14px", height: "14px", objectFit: "contain" }}
                  />
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(12),
                      fontWeight: typography.font_weight.regular,
                      color: "var(--grey-800)",
                    })}
                  >
                    {check.health_display_name}
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default ReportsList;
