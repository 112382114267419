import { addDays, startOfWeek, subDays } from "date-fns";
import {
  REPORTS_FEED_FILTER_RESET,
  REPORTS_FEED_TIMESTAMPS,
  REPORTS_FEED_UPDATE_TYPE,
} from "../constants/reportsConstants";

// Helper function to calculate previous week's start and end dates
const getPreviousWeekTimestamps = () => {
  const startOfCurrentWeek = startOfWeek(new Date(), { weekStartsOn: 0 }); // Start of this week (Sunday)
  const startOfPreviousWeek = subDays(startOfCurrentWeek, 7); // Go back one week
  const endOfPreviousWeek = addDays(startOfPreviousWeek, 6); // Add 6 days to get Saturday
  return {
    startDate: startOfPreviousWeek,
    endDate: endOfPreviousWeek,
  };
};

const default_filters = {
  timestamps: {
    ...getPreviousWeekTimestamps(),
    pipelineSchedule: "w",
  },
  type: null,
};

const getFiltersFromQueryParams = () => {
  const params = new URLSearchParams(window.location.search); // Copy the current params
  // While user onboarding flow when user goes to any route through userflow actions, page refreshes and it takes
  // The parameters of that page and embed them in reducer, due to which below condition.
  if (window.location.pathname !== "/reports") {
    return default_filters;
  }
  if (params.size) {
    const obj = {
      ...default_filters,
    };
    params.forEach((value, key) => {
      let updatedValue;

      // Check if the key corresponds to a filter that requires JSON parsing
      if (key === "startDate" || key === "endDate") {
        try {
          const date = new Date(value);
          updatedValue = date;
          obj.timestamps[key] = updatedValue;
        } catch (e) {
          return; // Skip dispatching this filter if parsing fails
        }
      } else {
        updatedValue = value;
        obj[key] = updatedValue;
      }
    });
    return obj;
  }
  return default_filters;
};

export const reportsFeedFilterReducer = (state = { ...getFiltersFromQueryParams() }, action) => {
  switch (action.type) {
    case REPORTS_FEED_TIMESTAMPS:
      let oldState = { ...state };
      return { ...oldState, timestamps: { ...action.payload } };
    case REPORTS_FEED_UPDATE_TYPE:
      let updatedState = { ...state, type: action.payload };
      return updatedState;
    case REPORTS_FEED_FILTER_RESET:
      return { ...default_filters };
    default:
      return state;
  }
};
