import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Stack, Tooltip, useScrollTrigger } from "@mui/material";
import Fab from '@mui/material/Fab';
import Fade from '@mui/material/Fade';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EventGroupsFeed from "../components/Events/EventGroupsFeed";
import NewEventSummary from "../components/Events/components/NewEventSummary";
import FeedContainer from "../components/FeedContainer";
import { EVENTS_FEED_FILTER_RESET, GROUP_EVENT_LIST_RESET } from "../constants/eventConstants";
import { SAAS_EVENT_SUMMARY_FEATURE_TOGGLE } from "../constants/featureFlagConstants";
import { isEnabled } from "../utils/featureFlags";

function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: process.env.REACT_APP_DEV_REV_APP_ID ? 22 : 16, right: process.env.REACT_APP_DEV_REV_APP_ID ? 90: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

const EventGroupScreen = () => {
  const dispatch = useDispatch();
  let isEventSummaryEnabled = isEnabled(SAAS_EVENT_SUMMARY_FEATURE_TOGGLE);
  const [isRestructured, setIsRestructured] = useState(false); // State to manage restructuring
  const eventsTimeFilter = useSelector((state) => state.eventsFeedFilters?.timestamps);
  const eventsSummaryFilter = useSelector((state) => state.eventsFeedFilters?.summaryFilters);
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 20 });

    // useEffect to monitor scroll position and adjust restructuring state
    useEffect(() => {
      if (trigger) {
        setIsRestructured(true);
      } else if (window.scrollY < 20) {
        // When back to the top, set isRestructured to false
        setIsRestructured(false);
      }
    }, [trigger]);

  useEffect(() => {
    return () => {
      dispatch({
        type: GROUP_EVENT_LIST_RESET,
        payload: [],
      });
      // Reset the filters applied on events feed
      dispatch({ type: EVENTS_FEED_FILTER_RESET });
    };
  }, []);

  return (
    <FeedContainer backgroundColor="var(--grey-50)" containerPadding="0px" containerWidth="100%">
      {isEventSummaryEnabled ? (
        <Stack direction="column" gap={5}>
          <NewEventSummary
            startTime={eventsTimeFilter?.startDate}
            endTime={eventsTimeFilter?.endDate}
            pipelineSchedule={eventsTimeFilter?.pipelineSchedule}
            isRestructured={isRestructured}
          />
        </Stack>
      ) : (
        <></>
      )}

      <EventGroupsFeed
        startTime={eventsTimeFilter?.startDate}
        endTime={eventsTimeFilter?.endDate}
      />
      <ScrollTop>
        <Tooltip title="Scroll back to top" placement="top-end">
          <Fab
            size="small"
            aria-label="scroll back to top"
            sx={{
              backgroundColor: "var(--mariner)",
              "&:hover": {
                backgroundColor: "var(--mariner)",
              },
            }}
          >
            <KeyboardArrowUpIcon sx={{ color: "var(--white)" }} />
          </Fab>
        </Tooltip>
      </ScrollTop>
    </FeedContainer>
  );
};

export default EventGroupScreen;
