import { Container, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useCallback, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { DIM_NAME_OTHER } from "../../constants/commonConstants";
import { InsightContext } from "../../context/InsightContext";
import { colors } from "../../shared/theme-constants";
import { isEmpty } from "../../utils/is";
import './DrillDownHotspotChart.css';
import TimeseriesChartDescription from "./TimeseriesChartDescription";
import BreadCrumbs from "./mui-wrapper-components/BreadCrumb";
import { ChartContainer } from "./styled-components";
import { ChartSubtitleTypography, ChartTitleTypography } from "./styled-components/Container.styled";
import { Message } from "./ui-components/Message";

const notify = ({ dimName, dimValue }) => {
  toast(`Drill down chart is not available for ${dimName}: ${dimValue}`, {
    duration: 2000,
  });
};

function DrillDownHotspotChart({ hotspotData, barChartConfig, styles, accent }) {
  const { patternData } = useContext(InsightContext);

  // First record will always be the Primary Driver chart, that has click enabled (at least one)
  // The rest of the records are the drill down charts, which can be access with `drillDownGroup`
  const [parentChart, ...drilldownCharts] = hotspotData;

  const [data, setData] = useState(parentChart.chartData.hotspotData);
  const [drilldownLevel, setDrilldownLevel] = useState(0);
  // This state is added for fixing the inconsistent bargap issue, it will ensure that the Plotly updates after the layout update
  const [currentChart, setCurrentChart] = useState(parentChart);
  const [otherBarText, setOtherBarText] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState([
    { text: "Primary Drivers", value: "primary_drivers" },
  ]);

  const [hoverInfo, setHoverInfo] = useState();
  const [hoverInfoText, setHoverInfoText] = useState("nulls omitted");

  // If there are no drill drill down bars with others text then do not generate the text
  const generateOtherBarsText = (currDrilldownChart, parentChart) => {
    let drillDownBars = currDrilldownChart.chartData?.hotspotData[0]?.customdata;
    for (let i = 0; i < drillDownBars.length; i++) {
      if (drillDownBars[i].dimValue === DIM_NAME_OTHER) {
        setOtherBarText(
          `*Other bar includes the weak drivers for the ${parentChart.dimName} of ${parentChart.dimValue}`
        );
      }
    }
  };

  useEffect(() => {
    if (!isEmpty(currentChart)) {
      setData(currentChart.chartData.hotspotData);
    }
  }, [currentChart]);

  const handleBreadCrumbOptionsChange = useCallback(
    (breadCrumbs) => {
      // dismiss other toast, if already open
      toast.dismiss();
      setBreadcrumbs([...breadCrumbs]);
      if (breadCrumbs.length === 1) {
        // here we know that user has clicked the first option on bread crumb which is the parent chart
        // we already have the varibale of parent chart hence directly using it instead of iterating again
        setCurrentChart(parentChart);
        setDrilldownLevel(0);
        // Set hover for nulls to empty as there won't be any nulls on primary drivers
        setHoverInfo(null);
      } else {
        for (let i = 0; i < drilldownCharts.length; i++) {
          if (drilldownCharts[i].chartData.hotspotData[0].customdata[0].dimName === breadCrumbs[breadCrumbs.length - 1].text) {
            setCurrentChart(drilldownCharts[i]);
            // set null values info
            let nullValue = drilldownCharts[i].chartData.hotspotData[0].nullValueData;
            if (nullValue && nullValue.length > 0) {
              setHoverInfo(nullValue[0]);
            } else {
              setHoverInfo(null);
            }
          }
        }
        setDrilldownLevel((prev) => prev - 1);
      }
    },
    [parentChart, drilldownCharts]
  );

  const handleNullInfoHover = () => {
    setHoverInfoText(
      `nulls omitted (${hoverInfo.kpiPopulationChangePct} Vol) ${hoverInfo.patternContribution} Impact`
    );
  };

  const handleBarClick = useCallback(
    (event) => {
      // dismiss other toast, if already open
      toast.dismiss();
      const customdata = event;
      const dName =
        drilldownLevel > 0
          ? customdata.dimName
          : (customdata.parentDimName
          ? customdata.parentDimName
          : customdata.dimName);
      const dValue =
        drilldownLevel > 0
          ? customdata.dimValue
          : (customdata.parentDimValue
          ? customdata.parentDimValue
          : customdata.dimValue);
      if (customdata && !customdata.isClickable) {
        notify({
          dimName: dName,
          dimValue: dValue,
        });
        return;
      }
      const currDrilldownChart = drilldownCharts.find((chart) => {
        for (let i = 0; i < chart?.chartData?.hotspotData[0].customdata.length; i++) {
          // check if drill down group exists for the given primary drivers chart
          if (chart?.chartData.hotspotData[0].customdata[i].group === customdata.drillDownGroup) {
            return true;
          }
        }
        return false;
      });
      if (!isEmpty(currDrilldownChart)) {
        // Inconsistent bargap issue workaround fix:
        // The plotly was not reflecting the chart layout correctly on first update, hence setting data to blank
        // and in the useEffect will set it to the currentDrillDownChart data, which will force Plotly to update
        let dimensionName = currDrilldownChart.chartData.hotspotData[0].customdata[0].dimName;
        let dimensionValue = currDrilldownChart.chartData.hotspotData[0].customdata[0].dimValue;
        setData([]);
        setBreadcrumbs(breadcrumbs => [...breadcrumbs,  { text: dimensionName, value: dimensionValue } ]);
        setDrilldownLevel((prev) => prev + 1);
        setCurrentChart(currDrilldownChart);
        generateOtherBarsText(currDrilldownChart, customdata);

        // set null values info
        let nullValue = currDrilldownChart.chartData.hotspotData[0].nullValueData;
        if (nullValue && nullValue.length > 0) {
          setHoverInfo(nullValue[0]);
        } else {
          setHoverInfo(null);
        }
      } else {
        notify({
          dimName: dName,
          dimValue: dValue,
        });
      }
    },
    [drilldownCharts, drilldownLevel]
  );

  const isTooltipEnabled = (chartWidth, point) => {
    const spaceAvailable = chartWidth - point.shapeArgs.height; // point.shapeArgs.height gives us the total area taken by bar on chart and on subtracting it with chart width gives us the available space on chart for datalabel.
    const label = point.dataLabel;
    const labelWidth = label.width;
    if (currentChart.chartData.chartLayout.xaxis.range[0] < 0) { // To check if y-axis has both negative and positive values.
      const halfPlotWidth = chartWidth / 2; // if y-axis is coming in middle then we take half of the chart width.
      const availableChartWidth = halfPlotWidth - point.shapeArgs.height; // Same as line 277.
      if (labelWidth > availableChartWidth) {
        return true;
      }
    } else if (labelWidth > spaceAvailable) {
      return true;
    }
    return false;
  };

  const generateHighchartsOptions = () => {

    const seriesObj = [
      {
        name: '',
        data: [],
      }
    ];

    let positiveAccentIndex = 0;
    let negativeAccentIndex = 0;
    currentChart.chartData.hotspotData[0].customdata.forEach((item, index) => {
      let barColor;
      if (item.kpiValueMovementDecimal > 0) {
        if (accent === "positive") {
          barColor = colors.hotspot_positive_accent_colorway[positiveAccentIndex];
          positiveAccentIndex += 1;
        } else {
          barColor = colors.hotspot_negative_accent_colorway[negativeAccentIndex];
          negativeAccentIndex += 1;
        }
      } else if (item.kpiValueMovementDecimal < 0) {
        if (accent === "positive") {
          barColor =
            colors.hotspot_negative_accent_colorway[
              colors.hotspot_negative_accent_colorway.length - 1 - negativeAccentIndex
            ];
            negativeAccentIndex += 1;
        } else {
          barColor =
            colors.hotspot_positive_accent_colorway[
              colors.hotspot_positive_accent_colorway.length - 1 - positiveAccentIndex
            ];
            positiveAccentIndex += 1;
        }
      }
      const highChartDataObj = {
        name: item.dimValue,
        y: item.kpiValueMovementDecimal,
        drilldown: item.dimName,
        dataLabel: currentChart.chartData.hotspotData[0].text[index],
        drilldown: item.dimName,
        color: barColor,
        ...item,
      };
      seriesObj[0].data.push(highChartDataObj);
    });
    const options = {
      chart: {
        type: "bar",
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      accessibility: {
        enabled: false,
      },
      yAxis: {
        type: "linear",
        lineWidth: 1,
        gridLineWidth: 0,
        min:
          currentChart.chartData.chartLayout.xaxis.range[0] < 0
            ? currentChart.chartData.chartLayout.xaxis.range[0]
            : 0, // Set the minimum value for the x-axis
        max: currentChart.chartData.chartLayout.xaxis.range[1],
        tickInterval: 1,
        title: {
          text: undefined, // Set the title text to undefined to remove it
        },
        labels:{
          enabled: false
        }
      },
      xAxis: {
        type: "category",
        categoryOrder: "total ascending",
        lineWidth: 1,
        crossing: 0,
        tickPositions: [], // Set an empty array to hide tick positions
      },
      plotOptions: {
        bar: {
          pointWidth: 25,
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: "allow",
            style: {
              fontWeight: "400",
              textOverflow: "ellipsis",
            },
            formatter: function () {
              // Customize the data label based on your requirements
              // You can access point properties using 'this.point'
              const label = this.point?.dataLabel || "";
              return label;
            },
          },
        },
        series: {
          cursor: "pointer",
          point: {
            events: {
              click: function (event) {
                handleBarClick(this.options);
              },
            },
          },
          events: {
            afterAnimate: function () {
              const chartWidth = this.chart.plotWidth; // Total Chart Area.
              this.points.forEach((point) => {
                const spaceAvailable = chartWidth - point.shapeArgs.height; // point.shapeArgs.height gives us the total area taken by bar on chart and on subtracting it with chart width gives us the available space on chart for datalabel.
                const label = point.dataLabel;
                const labelWidth = label.width;
                if (currentChart.chartData.chartLayout.xaxis.range[0] < 0) { // To check if y-axis has both negative and positive values.
                  const halfPlotWidth = this.chart.plotWidth / 2; // if y-axis is coming in middle then we take half of the chart width.
                  const availableChartWidth = halfPlotWidth - point.shapeArgs.height; // Same as line 277.
                  if (labelWidth > availableChartWidth) {
                    label.css({
                      'width': availableChartWidth + 'px',
                    });
                    // In below if condition we had to pass x attribute because on negative side of y-axis the datalabels were going out of the chart. So passed the kpiValueMovement as the starting point for datalabel.
                    if (point.options.y < 0) {
                      label.attr({
                        x: parseInt(point.options.kpiValueMovement),
                      });
                    }
                  }
                } else if (labelWidth > spaceAvailable) {
                  label.css({
                    'width': spaceAvailable + 'px',
                  });
                }
              });
            },
          }
        },
      },
      title: {
        text: "",
      },
      tooltip: {
        enabled: true,
        outside: true, // control whether the tooltip should be positioned outside the plot area.
        useHTML: true,
        style: {
          pointerEvents: 'auto', // Ensure tooltip captures events
      },
        formatter: function () {
          const isEnabled = isTooltipEnabled(this.series.chart.plotWidth, this.point);
          if (isEnabled) {
            const label = this.point.options.dataLabel || "";
            const labelWidth = this.point.dataLabel.width;
            let tooltipWidth = this.series.chart.plotWidth;
            if (labelWidth < this.series.chart.plotWidth) {
              tooltipWidth = labelWidth;
            }
            return '<div style="width: ' + tooltipWidth + 'px; word-wrap: break-word; white-space: normal;">' + label + '</div>';
          }
          return false;
        },
      },
      series: seriesObj,
    };
    return options;
  };

  return (
    <ChartContainer>
      <Stack>
        <Box
          sx={{
            padding: "5px 0 0 0px",
          }}
        >
          {!isEmpty(breadcrumbs) ? <BreadCrumbs breadCrumbs={breadcrumbs} onChange={handleBreadCrumbOptionsChange} /> : null}
        </Box>
      </Stack>
      <Box sx={{ "& > div": { minHeight: 450 } }}>
        {!isEmpty(data) ? (
          <HighchartsReact highcharts={Highcharts} options={generateHighchartsOptions()} />
        ) : (
          <Message>Loading...</Message>
        )}
      </Box>
      {hoverInfo && (
        <Stack direction="row" sx={{ justifyContent: "flex-end" }}>
          <Box
            onMouseEnter={() => handleNullInfoHover()}
            onMouseLeave={() => setHoverInfoText("nulls omitted")}
            sx={(theme) => ({
              ":hover":{
                cursor: "pointer"
              },
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              minWidth: 160,
              border: "2px solid var(--mariner)",
              borderRadius: 10,
              height: 40,
              marginTop: -30,
              zIndex: 1000,
            })}
          >
            <Typography
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(12),
                ml: 3
              })}
            >
              {hoverInfoText}
            </Typography>
            <img style={{ width: 25, height: 25, margin: "0 10px 0 10px" }} src={`${process.env.REACT_APP_CDN_BASE_URL}/images/hover-info-icon.png`} />
          </Box>
        </Stack>
      )}
      <ChartTitleTypography
        sx={{
          mt: 6
        }}
      >
        {currentChart.chartSummary}
      </ChartTitleTypography>
      {drilldownLevel > 0 ? (
        <ChartSubtitleTypography
          sx={{
            fontSize: "14px",
            mt: 4,
          }}
        >
          {otherBarText}
        </ChartSubtitleTypography>
      ) : null}
      {drilldownLevel === 0 ? (
        <Container
          disableGutters
          maxWidth={false}
        >
          <TimeseriesChartDescription description={patternData.pattern.chart_description} />
        </Container>
      ) : null}
    </ChartContainer>
  );
}

export default DrillDownHotspotChart;
