import React, { useState } from "react";
import "./summary.css";
import { CardActionArea, CardActions, IconButton, Stack, Box } from "@mui/material";
import { InsightTitleSummaryEdit } from "../../common/InsightTitleSummaryEdit";
import Icon from "../../common/Icon";
import InsightTitleSummaryView from "../../common/InsightTitleSummaryView";
import useBoolean from "../../../hooks/useBoolean";
import { bp, theme } from "../../common/styled-components/theme";
import { stripInlineLinks, stripMarkdown } from "../../../services/markdown";

const ChartHeaderBase = ({ chartName, children }) => (
  <Stack
    direction="row"
    width="90%"
    sx={{ [bp.min(theme.tablet)]: { gap: "20px" } }}
  >
    <Box>
      <img width="100px" height="110px" src={`${process.env.REACT_APP_CDN_BASE_URL}/images/${chartName}.svg`} />
    </Box>

    {children}
  </Stack>
);

function InsightCardHeader({
  groupId,
  isModal,
  isOpen,
  miniChartName,
  summary,
  title,
  accent,
  onClick,
  insightId,
  insightType,
  funnels = [],
}) {
  // temporary solution
  // title and summary should be read from one place
  // due to current implementation, we can either intercept values here
  // and sync them in Edit component, or prop drill setter function from root parent component
  const [localTitleSummary, setLocalTitleSummary] = useState({
    title,
    summary,
  });
  const [isEditing, { setTrue: enableEditing, setFalse: disableEditing }] =
    useBoolean();

  return (
    <>
      {isEditing ? (
        <ChartHeaderBase chartName={miniChartName}>
          <InsightTitleSummaryEdit
            allowEdit
            groupId={groupId}
            insightId={insightId}
            isModal={isModal}
            onEditCancel={disableEditing}
            summary={stripMarkdown(summary)}
            title={stripMarkdown(title)}
            setLocalTitleSummary={setLocalTitleSummary}
          />
        </ChartHeaderBase>
      ) : (
        <CardActionArea
          onClick={onClick}
          disableRipple
          sx={{
            font: "inherit",
            "& .MuiCardActionArea-focusHighlight": { display: "none" },
          }}
        >
          <ChartHeaderBase chartName={miniChartName}>
            <InsightTitleSummaryView
              accent={accent}
              title={stripInlineLinks(localTitleSummary.title)}
              summary={localTitleSummary.summary}
              showLegend={isOpen}
              insightType={insightType}
              allowEdit
              funnels={funnels}
            />
          </ChartHeaderBase>
        </CardActionArea>
      )}

      {/* Move this component outside root component so we don't get
          validateDOMNesting(…): <button> cannot appear as a descendant of <button> */}
      <CardActions
        data-snippyly-comment-disabled
        sx={{
          position: "absolute",
          display: isEditing && "none",
          p: 0,
          top: isModal ? 10 : 20,
          right: isModal ? 40 : 20,
          // pull button outside container for the size of the padding
          // to achieve visual alignment with container
          mt: "-8px",
          mr: "-8px",
        }}
      >
        <IconButton type="button" onClick={enableEditing}>
          <Icon name="edit-summary" size="20px" />
        </IconButton>
      </CardActions>
    </>
  );
}

export default InsightCardHeader;
