import { Box } from "@mui/material";
import { formatISO } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, LoaderInCenter } from "../components/common/styled-components";
import ReportsHeader from "../components/Reports/ReportsHeader";
import ReportsList from "../components/Reports/ReportsList";
import { REPORTS_FEED_UPDATE_TYPE } from "../constants/reportsConstants";
import { useAxios } from "../hooks/useAxios";
import { useSelectedTenant } from "../hooks/useSelectedTenant";
import { isEmpty } from "../utils/is";

const EventGroupScreen = () => {
  const dispatch = useDispatch();
  const selectedTenant = useSelectedTenant();
  const tenantGlobalFilter = useSelector((state) => state.tenantGlobalFilter?.appliedFilter);
  const reportsFeedFilter = useSelector((state) => state.reportsFeedFilters);
  const [providerTypes, setProviderTypes] = useState([]);
  const [providersData, setProvidersData] = useState([]);

  const params = {
    tenant_id: selectedTenant,
    dim_name: tenantGlobalFilter?.dimension_name,
    dim_val: tenantGlobalFilter?.dimension_value,
    start_time: formatISO(reportsFeedFilter?.timestamps.startDate, { representation: "date" }),
    end_time: formatISO(reportsFeedFilter?.timestamps.endDate, { representation: "date" }),
  };

  const {
    data: reportsData,
    status: status,
    isLoading: fetchingReports,
  } = useAxios("events/reports", {
    params: params,
  });

  useEffect(() => {
    if (!isEmpty(reportsData)) {
      let providers = [];
      const filteredProviderTypes = reportsData.data?.provider_types.map((item) => ({
        value: item?.provider_type_name,
        label: item?.provider_type_display_name,
      }));
      if (reportsFeedFilter?.type) {
        const selectedTypeDetails = reportsData.data?.provider_types.find(item => item.provider_type_name === reportsFeedFilter?.type);
        providers = selectedTypeDetails.providers;
      } else {
        providers = reportsData.data?.provider_types[0].providers;
        dispatch({
          type: REPORTS_FEED_UPDATE_TYPE,
          payload: filteredProviderTypes[0].value,
        });
      }
      setProviderTypes(filteredProviderTypes);
      setProvidersData(providers);
    }
  }, [reportsData, dispatch, reportsFeedFilter]);

  if (fetchingReports) {
    return <LoaderInCenter />;
  }

  const handleProviderTypeUpdate = (updatedType) => {
    const selectedTypeDetails = reportsData.data?.provider_types.find(item => item.provider_type_name === updatedType);
    if (selectedTypeDetails) {
      setProvidersData(selectedTypeDetails.providers);
    }
  };

  return (
    <Container bgColor="var(--grey-50)" containerPadding="0px">
      <Box
        sx={(theme) => ({
          width: "100%",
          maxWidth: "100%",
          minWidth: "400px",
          padding: "0px",
          margin: "0 auto",
          marginTop: "0px",
          [theme.breakpoints.down("tm")]: {
            minWidth: "unset"
          },
        })}
      >
        <ReportsHeader providerTypes={providerTypes} handleProviderType={handleProviderTypeUpdate} />
        <ReportsList providersData={providersData} />
      </Box>
    </Container>
  );
};

export default EventGroupScreen;
