import styled from "@emotion/styled";
import { Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import moment from "moment-timezone";
import React from "react";
import { useAxios } from "../../hooks/useAxios";
import { useSelectedTenant } from "../../hooks/useSelectedTenant";
import ListLoader from "../common/ListLoader";
import MetricTimeSeriesChart from "./MetricTimeSeriesChart";
import { TOP_METRICS_CONTEXT } from "../../constants/featureContexts";
import MetricImpact from "../common/MetricImpact";
import { useNavigate } from "react-router-dom";

const StyledMetricPanelCard = styled(Card)(({ theme }) => ({
  boxShadow: "0px 2px 15px 0px #11278529",
  minHeight: theme.typography.pxToRem(230),
  ":hover": {
    cursor: "pointer",
    transform: "scale3d(1.05,1.05,1.05)",
    transition: "transform 0.2s ease-out",
  },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
}));

const MetricError = ({ errorMessage }) => (
  <Stack
    gap={4}
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <img width="80px" height="80px" src={`${process.env.REACT_APP_CDN_BASE_URL}/icons/NorthStarMetric.svg`} />

    <Typography color="secondary.main" fontSize="20px" textAlign="center">
      {errorMessage ? errorMessage : "Unable to get data for North Star Metric"}
    </Typography>
  </Stack>
);

const MetricsPanelCard = ({
  metricId,
  startDate,
  endDate,
  pipeline_schedule,
  impact_pipeline_schedule,
  isForecastEnabled,
  tenantGlobalFilter,
  impactEndDate,
  showPreviousPeriodComparison = false,
  previousPeriodstartDate,
  selectedPipelineFrequency,
  //   metricError: MetricError,
}) => {
  const selectedTenant = useSelectedTenant();
  const navigate = useNavigate();
  const { data: metricDetailsData, status: metricDetailsStatus } = useAxios(
    `metrics_hub/metrics/${metricId}`
  );
  const handleCardClick = (kpi_id) => {
    navigate(`/metrics/${kpi_id}`);
  };

  if (metricDetailsStatus == "loading") return <ListLoader></ListLoader>;

  return (
    <StyledMetricPanelCard onClick={() => handleCardClick(metricDetailsData.kpi_id)}>
      <CardHeader
        sx={{ paddingBottom: 2 }}
        title={
          <StyledTitle color="secondary.main" gutterBottom>
            {metricDetailsData.kpi_display_name}
          </StyledTitle>
        }
      ></CardHeader>
      <CardContent sx={{ paddingTop: 0 }}>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            height: 200,
            width: "100%",
            justifyContent: "center",
          }}
        >
          <MetricImpact
            kpi_id={metricDetailsData?.kpi_id}
            pipeline_schedule={impact_pipeline_schedule}
            timestamp_start={startDate}
            timestamp_end={impactEndDate}
          />
          <MetricTimeSeriesChart
            startDate={startDate}
            endDate={endDate}
            patternData={{
              pattern: {
                kpi_format: metricDetailsData?.kpi_format,
                tenant_id: selectedTenant,
                kpi_name: metricDetailsData?.kpi_name,
                kpi_id: metricDetailsData?.kpi_id,
                kpi_display_name: metricDetailsData?.kpi_display_name,
                accent: metricDetailsData?.accent,
                pipeline_schedule: pipeline_schedule,
              },
            }}
            context={TOP_METRICS_CONTEXT}
            metricError={MetricError}
            isForecastEnabled = {isForecastEnabled}
            tenantGlobalFilter={tenantGlobalFilter}
            showPreviousPeriodComparison={showPreviousPeriodComparison}
            previousPeriodstartDate={previousPeriodstartDate}
            selectedPipelineFrequency={selectedPipelineFrequency}
          />
        </Stack>
      </CardContent>
    </StyledMetricPanelCard>
  );
};

export default MetricsPanelCard;
