export const GROUP_EVENT_LIST_REQUEST = "EVENTS/GROUP_EVENT_LIST_REQUEST";
export const GROUP_EVENT_LIST_SUCCESS = "EVENTS/GROUP_EVENT_LIST_SUCCESS";
export const GROUP_EVENT_LIST_FAIL = "EVENTS/GROUP_EVENT_LIST_FAIL";
export const GROUP_EVENT_LIST_RESET = "EVENTS/GROUP_EVENT_LIST_RESET";

export const EVENTS_FEED_TIMESTAMPS = "EVENTS/FEED/TIMESTAMPS/UPDATE";
export const EVENTS_FEED_FILTER_RESET = "EVENTS/FEED/FILTER/RESET";

export const EVENTS_FEED_SUMMARY_FILTERS = "EVENTS/FEED/SUMMARY_FILTERS/UPDATE";
export const EVENTS_FEED_CLEAR_FILTERS = "EVENTS/FEED/CLEAR_FILTERS/CLEAR";

export const EVENT_TYPE_OUTAGE = "outage";
export const EVENT_TYPE_ERROR = "error";

// Third party provider Errors and outages
export const EVENT_SUB_TYPE_OUTAGE = null;
export const EVENT_SUB_TYPE_CDP = "cdp";
// Browser Errors
export const EVENT_SUB_TYPE_JAVASCRIPT = "js";
export const EVENT_SUB_TYPE_BEACON = "beacon";
export const EVENT_SUB_TYPE_NETWORK = "network";
export const EVENT_SUB_TYPE_ASSET = 'asset';
// Customer Events
export const EVENT_SUB_TYPE_EXPERIRENCE = "experience";

export const BROWSER_EVENT_SUB_TYPES = [ EVENT_SUB_TYPE_JAVASCRIPT, EVENT_SUB_TYPE_BEACON, EVENT_SUB_TYPE_NETWORK, EVENT_SUB_TYPE_ASSET];

