import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { labels } from "../../../shared/intl/labels";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment-timezone";
import { DEFAULT_TIMEZONE, PERCENTAGE } from "../../../constants/commonConstants";
import {
  compareMetricHighChartLayout
} from "../../../shared/plotly-config";
import { colors } from "../../../shared/theme-constants";
import { groupBy } from "../../../utils/arrayHelper";
import { formatDatePerFrequency } from "../../../utils/dateUtils";
import { isEmpty } from "../../../utils/is";
import { nFormatter } from "../../../utils/stringUtils";
import { Message } from "../../common/ui-components/Message";

const getMarkerColors = (
    anomalies,
    index,
    isCurrentMetricTopMetric,
    selectedMetrics,
    from,
) => {
    return anomalies.map((anomaly) => {
        if (anomaly) {
            return colors.pink[500];
        } else {
            return getLineColor(index, isCurrentMetricTopMetric, selectedMetrics, from);
        }
    });
};

const getLineColor = (index, isCurrentMetricTopMetric, selectedMetrics, from) => {
  if (isCurrentMetricTopMetric) {
    return colors.topMetricColorMap[selectedMetrics?.kpis?.length > 1 ? 0 : 1];
  } else if (index < 8) {
    return colors.metricColorMap[index];
  } else return "";
};

const getHoverTemplateY = (yValue, kpiFormat) => {
  return kpiFormat === PERCENTAGE ? `${Math.round(yValue * 100)}%` : `${nFormatter(yValue, 1)}`;
};

export const CompareMetricsChart = ({
    data,
    enableForecast,
    enableLogScale,
    topMetric,
    pipelineSchedule,
    isForecastEnabled,
    groupingBy = "kpi_name",
    firstSelectedDimension = null,
    isTitleRequired = true,
    plotBands = [],
    timezone = null,
    isTimeRequired = false,
}) => {
    const [loading, setLoading] = useState(true);
    const [xAxis, setXAxis] = useState([]);
    const [highChartData, setHighChartData] = useState([]);
    const selectedMetrics = useSelector(
        (state) => state.collaborateFilters,
        (previousValue, currentValue) => {
            // Check if filters have changed
            if (previousValue !== currentValue) {
                setLoading(true);
            }
        }
    );

    // This will decide how many ticks are present on the x axis and what is the second wise gap between the ticks
    const generateTimeSeriesChartTickInterval = (frequency) => {
        switch (frequency) {
          case "h":
            return data?.length > 10 ? 4 * 3600 * 1000 : 3600 * 1000;
          case "d":
            return  24 * 3600 * 1000;
          case "w":
            return 14 * 24 * 3600 * 1000;
          case "m":
            return  30 * 24 * 3600 * 1000;
          case "q":
            return 90 * 24 * 3600 * 1000;
          default:
            return 3600 * 1000;
        }
      };
    useEffect(() => {
        const metricData = [];
        const series = [];
        const x = [];
        data?.length &&
            data.forEach((datum) => {
                // Prepare x axis
                x.push(datum.timestamp);
                // Prepare the raw data for the selected metrics
                Object.keys(datum.results).map((key) => {
                    const metric = datum.results[key];
                    metricData.push(metric);
                });
            });
        const group = groupBy(metricData, groupingBy);
        if (!isEmpty(group)) {
            Object.keys(group).reverse().map((item, index) => {
                const currentMetric = group[item];
                const isCurrentMetricTopMetric = item === topMetric.kpi_name;
                const anomaly = [];
                const y = [];
                const range = [];

                // In case of comparing Charts against different dimension values
                // we need to show the Ci band only for the dimension value which comes
                // first in the sequence in the `dimensions` query param
                let isFirstSelectedDimensionValue = false;
                const yWithInsight = [];

                if(groupingBy === "dim_val"){
                    isFirstSelectedDimensionValue = firstSelectedDimension === item;
                }
                const selectedMetric =
                    selectedMetrics?.kpis?.length &&
                    selectedMetrics.kpis.filter(
                        (selected) => selected.kpi_name === item
                    );
                const traceName =
                  groupingBy === "dim_val" ? item : selectedMetric[0]?.kpi_display_name;
                const kpiFormat =
                  groupingBy === "dim_val"
                    ? selectedMetrics?.kpis[0]?.kpi_format
                    : selectedMetric[0]?.kpi_format;

                currentMetric.forEach((datapoint, index) => {
                    const hasInsightObject = Object.keys(datapoint.insight).length > 0;

                    // Push the hasInsightObject value along with y value to an array of objects
                    yWithInsight.push({ y: datapoint.y, hasInsight: hasInsightObject, patternType: datapoint?.insight?.pattern_type });

                    y.push(datapoint.y);
                    const metricRange = [moment(x[index])?.valueOf(), datapoint.yhat_lower, datapoint.yhat_upper];
                    range.push(metricRange);
                    anomaly.push(hasInsightObject);
                });
                const markerColors = getMarkerColors(
                    anomaly,
                    index,
                    isCurrentMetricTopMetric,
                    selectedMetrics,
                );
                const traceMetric = {
                    name: traceName,
                    data: y.map((yValue, dataIndex) => ({
                        y: yValue,
                        x: moment(x[dataIndex]).valueOf(),
                        color: markerColors[dataIndex] // Use marker color corresponding to data index
                    })),
                    type: 'spline',
                    yAxis: 0,
                    zIndex: 1,
                    lineWidth: 2,
                    color: getLineColor(index, isCurrentMetricTopMetric),
                    marker: {
                        enabled: true,
                        lineWidth: 2.5,
                        radius: 7,
                        lineColor: 'white',
                        symbol: 'circle',
                        fillColor: null,
                    },
                    options: {
                        additionalKeys: {
                            kpiFormat: kpiFormat,
                            yWithInsight: yWithInsight,
                        },
                    }
                };
                if (kpiFormat === PERCENTAGE) {
                    traceMetric["yAxis"] = 1;
                  }
                series.push(traceMetric);
                // Prepare forecast trace based on the forecast switch state and only for the top metric
                if (isForecastEnabled && isCurrentMetricTopMetric) {
                    const rangeForecast = {
                        name: labels.SPIKES_CHART.LEGENDS.EXPECTED_RANGE,
                        data: range,
                        yAxis: kpiFormat === PERCENTAGE ? 1 : 0,
                        type: 'areasplinerange',
                        lineWidth: 0,
                        color: colors.blue[300],
                        fillOpacity: 0.3,
                        zIndex: 0,
                        marker: {
                            enabled: false
                        },
                        options: {
                            additionalKeys: {
                                kpiFormat: kpiFormat,
                            },
                        }
                    };
                    series.push(rangeForecast);
                }else if(groupingBy === "dim_val" && isFirstSelectedDimensionValue){
                    const rangeForecastForDimensionSplit = {
                        name: labels.SPIKES_CHART.LEGENDS.EXPECTED_RANGE,
                        data: range,
                        yAxis: kpiFormat === PERCENTAGE ? 1 : 0,
                        type: 'areasplinerange',
                        lineWidth: 0,
                        color: colors.blue[300],
                        fillOpacity: 0.3,
                        zIndex: 0,
                        marker: {
                            enabled: false
                        },
                        options: {
                            additionalKeys: {
                                kpiFormat: kpiFormat,
                            },
                        }
                    };
                    series.push(rangeForecastForDimensionSplit);
                };
                setHighChartData(series);
            });
            setHighChartData(series);
            setXAxis(x);
        }
    }, [
        data,
        enableForecast,
        enableLogScale,
        selectedMetrics,
        topMetric.kpi_name,
        groupingBy,
        isForecastEnabled,
        firstSelectedDimension
    ]);

    if (isEmpty(data)) {
        return (
            <Message>{"There's no data to show for the selected period."}</Message>
        );
    }

    const generateHighchartsOptions = () => {

        const options = {
            ...compareMetricHighChartLayout,
              ...(!isTitleRequired && { title: { text: '' }}),
            xAxis: {
                ...compareMetricHighChartLayout.xAxis,
                categories: xAxis?.map(element => moment(element).valueOf()),
                plotBands: plotBands && plotBands.length ? plotBands : [],
                tickInterval: generateTimeSeriesChartTickInterval(pipelineSchedule),
                labels: {
                    formatter: function () {
                        return formatDatePerFrequency(this.value, pipelineSchedule, timezone === null ? DEFAULT_TIMEZONE : timezone);
                    },
                    style: {
                        color: 'rgba(36, 90, 230, 1)', // Set color for percentage x-axis labels
                    },
                }
            },
            yAxis: [{
                ...compareMetricHighChartLayout.yAxis,
                type: enableLogScale ? 'logarithmic' : 'linear',
                labels: {
                    style: {
                        color: 'rgba(36, 90, 230, 1)', // Set color for percentage y-axis labels
                        fontFamily: 'Poppins', // Set font family for percentage y-axis labels
                        fontSize: '14px', // Set font size for percentage y-axis labels
                    },
                    formatter: function () {
                        // Convert the value to millions (M) format if >= 1 million
                        if (Math.abs(this.value) >= 1000000) {
                            return Highcharts.numberFormat(this.value / 1000000, 1) + 'M';
                        }
                        // Convert the value to thousands (K) format if >= 1 thousand
                        else if (Math.abs(this.value) >= 1000) {
                            return Highcharts.numberFormat(this.value / 1000, 0) + 'K';
                        }
                        // Otherwise, return the original value
                        else {
                            return Highcharts.numberFormat(this.value, 0);
                        }
                    }
                },
            },

            // Secondary y-axis for percentage metrics
            {
                ...compareMetricHighChartLayout.yAxis,
                ...compareMetricHighChartLayout.yAxis2,
                labels: {
                    style: {
                        color: 'rgba(36, 90, 230, 1)', // Set color for percentage y-axis labels
                        fontFamily: 'Poppins', // Set font family for percentage y-axis labels
                        fontSize: '14px', // Set font size for percentage y-axis labels
                    },
                    formatter: function () {
                        return Highcharts.numberFormat(this.value * 100, 0) + '%'; // Format labels as percentages
                    }
                },
            }
        ],
            tooltip: {
                crosshairs: true,
                shared: true,
                formatter: function () {
                    const points = this.points;
                    let tooltip = `<b>${moment(this.x).tz(timezone === null ? DEFAULT_TIMEZONE : timezone).format(!isTimeRequired ? 'DD MMM, YYYY' : 'DD MMM, YYYY, h A')}</b><br/>`; // Format date
                    points.forEach(point => {
                        const seriesName = point.series.name;
                        const kpiFormat = point.series.options.options?.additionalKeys?.kpiFormat;
                        let formattedValue;
                        let datapoint = point.y;
                        let yWithInsight;
                        let hasInsight = false;
                        if (point.series.options.options?.additionalKeys?.yWithInsight) {
                            // Find the corresponding y value in hasInsightData array and retrieve hasInsight value
                            yWithInsight = point.series.options.options.additionalKeys.yWithInsight.find(item => item.y === point.y);
                            if (yWithInsight) {
                                hasInsight = yWithInsight.hasInsight;
                            }
                        }
                        if (seriesName === labels.SPIKES_CHART.LEGENDS.EXPECTED_RANGE) {
                            // Format range values
                            const low = point.point.low;
                            const high = point.point.high;
                            formattedValue = `${getHoverTemplateY(
                                low,
                                kpiFormat
                            )} - ${getHoverTemplateY(high, kpiFormat)}`;
                        } else {
                            // Format metric values
                            formattedValue = hasInsight ? `${getHoverTemplateY(datapoint, kpiFormat)} (${yWithInsight.patternType})` : `${getHoverTemplateY(datapoint, kpiFormat)}`;
                        }
                        tooltip += `<span style="color:${point.color}">\u25CF</span> ${seriesName}: <b>${formattedValue}</b><br/>`;
                    });
                    return tooltip;
                }
            },
            series: highChartData,
        };
        return options;
    };

    return <HighchartsReact highcharts={Highcharts} options={generateHighchartsOptions()} />;
};
