import { Avatar, Box, Button, Container, Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser";
import { typography } from "../../shared/theme-constants";
import { StyledActionButton } from "../common/mui-wrapper-components/Button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const StripeBillingScreen = () => {
  const user = useAuthenticatedUser();
  const userObject = useSelector((state) => state.userLogin);
  const navigate = useNavigate();

  const handleBackToShopify = () => {
    navigate('/onboard/app_installation');
  };

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "var(--grey-50)",
        height: useMediaQuery("(max-width:400px)") ? "100%" : "100vh",
      }}
    >
      {/* User info box */}
      <Box
        sx={{
          position: "absolute",
          top: "16px",
          right: "16px",
          backgroundColor: "var(--white)",
          padding: "8px",
          borderRadius: "4px",
          backgroundColor: "transparent",
        }}
      >
        <Typography
          sx={(theme) => ({
            fontSize: theme.typography.pxToRem(12),
            fontWeight: typography.font_weight.regular,
            color: "var(--grey-600)",
          })}
        >
          Logged in as:
        </Typography>
        <Typography
          sx={(theme) => ({
            fontSize: theme.typography.pxToRem(13),
            fontWeight: typography.font_weight.mediumx,
            color: "var(--grey-800)",
          })}
        >
          {user?.email || "Not logged in"}
        </Typography>
      </Box>
      <Box
        elevation={3}
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "auto",
          width: "100vw",
          maxWidth: theme.typography.pxToRem(460),
          boxShadow: "none",
          backgroundColor: "transparent",
          [theme.breakpoints.up("tm")]: {
            width: `${theme.typography.pxToRem(460)} !important`, // Increase height on smaller screens
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
            marginBottom: "20px",
            width: "100%",
            [theme.breakpoints.down("lt")]: { marginTop: "70px" },
          })}
          gap={6}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
              width: "100%",
            }}
            gap={6}
          >
            <Typography
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(22),
                fontWeight: typography.font_weight.mediumx,
                color: "var(--grey-900)",
                textAlign: "center",
              })}
            >
              Boost your digital efficiency with
              <br />
              Out Of The Blue Add-ons
            </Typography>
            <Typography
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(12),
                fontWeight: typography.font_weight.mediumx,
                color: "var(--grey-700)",
                textAlign: "center",
              })}
            >
              Stay ahead of platform errors and secure expert support with
              <br />
              our tailored solutions, exclusive to Blotout customers.
            </Typography>
          </Box>
          <Paper
            elevation={3}
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "auto",
              width: "auto",
              // maxWidth: theme.typography.pxToRem(460),
              boxShadow: "0 5px 20px 0 rgba(0, 0, 0, 0.15)",
              backgroundColor: "var(--white)",
              padding: "24px",
              borderRadius: "8px",
              gap: "24px",
            })}
          >
            <Stack
              direction="row"
              gap={4}
              sx={(theme) => ({
                alignItems: "center",
                justifyContent: "space-between",
                [theme.breakpoints.down("lt")]: { flexDirection: "column" },
              })}
            >
              <Box sx={{ border: "1px solid #2C50FF33", borderRadius: "8px", padding: "8px", backgroundColor: "#225CEB05" }}>
                <stripe-buy-button
                  buy-button-id={process.env.REACT_APP_STRIPE_PULSE_PIXEL_INSURANCE_MONTHLY_PAYMENT_LINK}
                  publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
                  client-reference-id={user?.tenant_id}
                  customer-email={user?.email}
                ></stripe-buy-button>
              </Box>
              <Box sx={{ border: "1px solid #2C50FF33", borderRadius: "8px", padding: "8px", backgroundColor: "#225CEB05" }}>
                <stripe-buy-button
                  buy-button-id={process.env.REACT_APP_STRIPE_PULSE_DONE_FOR_YOU_MONTHLY_PAYMENT_LINK}
                  publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
                  client-reference-id={user?.tenant_id}
                  customer-email={user?.email}
                ></stripe-buy-button>
              </Box>
            </Stack>
          </Paper>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          position: "fixed",
          bottom: 0,
          width: "100%",
          height: "50px",
          display: "flex",
          flexDirection: "row",
          marginLeft: "40px",
          justifyContent: "flex-start",
          [theme.breakpoints.down("lt")]: {
            marginRight: "0px",
            justifyContent: "center",
            position: "unset",
          },
        })}
      >
        <span
          style={{
            fontSize: "12px",
            fontWeight: "normal",
            color: "#6B6D77",
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            justifyContent: "left",
          }}
        >
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "left",
              justifyContent: "left",
              fontWeight: "bold",
              color: "#6B6D77",
            }}> Need Help? Email us:</span>
          <span style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "left",
            justifyContent: "left",
            color: "#6B6D77",
          }}><a
            href="mailto:connect@outoftheblue.ai"
            target="_blank"
            rel="noreferrer"
            style={{
              color: "inherit",
              textDecoration: "none",
            }}
            cursor="pointer"
          >connect@outfotheblue.ai</a>
          </span>
        </span>
      </Box>
    </Container>
  );
};
export default StripeBillingScreen;
