import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CalendarMonthOutlined from "@mui/icons-material/CalendarMonthOutlined";
import { IconButton } from "@mui/material";
import {
  add,
  compareAsc,
  endOfWeek,
  formatISO,
  parseISO,
  startOfDay,
  startOfWeek,
  sub
} from "date-fns";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { DEFAULT_TIMEZONE, PIPELINE_SCHEDULE_TRANSLATION } from "../../../constants/commonConstants";
import { REPORTS_FEED_TIMESTAMPS } from "../../../constants/reportsConstants";
import { typography } from "../../../shared/theme-constants";
import { getFormattedTimePeriod, localToUTC } from "../../../utils/dateUtils";
import MultiSelect from "../../common/mui-wrapper-components/Select/MultiSelect";
import { StyledInput } from "../../common/styled-components/collaborate/MultiSelect.styled";

const PERIOD_TO_DURATION = {
  week: { weeks: 1 },
  month: { months: 1 },
  quarter: { months: 3 },
};

const ReportFeedTimeFilter = () => {
  const defaultDates = useSelector((state) => state.reportsFeedFilters.timestamps);
  const tenantConfigs = useSelector((state) => state.tenantConfigs.configs);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedPipelineFrequency, setSelectedPipelineFrequency] = useState("week");
  const [startDate, setStartDate] = useState(defaultDates?.startDate);
  const [endDate, setEndDate] = useState(defaultDates.endDate);

  function setStartAndEndDates(frequency, date) {
    // Check if type of date is string, if yes, parse it to Date object
    if (typeof date === "string") {
      date = localToUTC(parseISO(date));
    } else if (typeof date === "object" && !(date instanceof Date)) {
      // Check if date is key-value object and not a date object, if yes, use the object to set the date based on frequency
      date = localToUTC(parseISO(date[frequency]));
    }
    let timesStampsObj = {};
    if (frequency === "week") {
      timesStampsObj.startDate = startOfWeek(date, { weekStartsOn: 0 });
      if (compareAsc(new Date(), endOfWeek(date, { weekStartsOn: 0 })) === 1) {
        timesStampsObj.endDate = endOfWeek(date, { weekStartsOn: 0 });
      } else {
        timesStampsObj.endDate = new Date();
      }
      timesStampsObj.pipelineSchedule = "w";
      setStartDate(timesStampsObj.startDate);
      setEndDate(timesStampsObj.endDate);
    }
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.set("startDate", formatISO(timesStampsObj.startDate, { representation: "date" }));
    updatedParams.set("endDate", formatISO(timesStampsObj.endDate, { representation: "date" }));
    setSearchParams(updatedParams);
    dispatch({ type: REPORTS_FEED_TIMESTAMPS, payload: timesStampsObj });
  }

  function handlePrevClick(selectedOption) {
    const newEndDate = sub(endDate, PERIOD_TO_DURATION[selectedPipelineFrequency]);
    setStartAndEndDates(selectedPipelineFrequency, newEndDate);
  }

  function handleNextClick(selectedOption) {
    const newEndDate = add(endDate, PERIOD_TO_DURATION[selectedPipelineFrequency]);
    if (compareAsc(new Date(), newEndDate) === 1) {
      setStartAndEndDates(selectedPipelineFrequency, newEndDate);
    } else {
      setStartAndEndDates(selectedPipelineFrequency, new Date());
    }
  }

  const options = [
    {
      label: "Weekly",
      value: "week",
    },
    {
      label: "Monthly",
      value: "month",
    },
    {
      label: "Quarterly",
      value: "quarter",
    },
  ];

  const handlePeriodChange = (event, newValue) => {
    setSelectedPipelineFrequency(newValue.value);
    setStartAndEndDates(newValue.value, new Date());
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <IconButton
        onClick={handlePrevClick}
        sx={{
          height: "40px",
          border: "1px solid #EBEAED",
          borderRadius: "8px",
          borderTopRightRadius: "0px",
          borderBottomRightRadius: "0px",
        }}
      >
        <ArrowBackIcon />
      </IconButton>
      <MultiSelect
        multiple={false}
        open={false}
        disableClearable
        disableCloseOnSelect={false}
        onChange={handlePeriodChange}
        options={options}
        value={options.find((item) => item.value === selectedPipelineFrequency)}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        optionsCheckbox={false}
        enableEllipsis
        renderInput={(params) => {
          const formattedValue = getFormattedTimePeriod(
            defaultDates?.startDate,
            defaultDates?.endDate,
            PIPELINE_SCHEDULE_TRANSLATION[defaultDates?.pipelineSchedule]
          );
          return (
            <StyledInput
              {...params}
              ref={params.InputProps.ref}
              inputProps={{
                ...params.inputProps,
                value: formattedValue,
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: formattedValue ? ( // Show the logo if the value exists
                  <CalendarMonthOutlined />
                ) : null,
                endAdornment: null,
                readOnly: true, // Makes input non-editable
                onKeyDown: (e) => e.preventDefault(), // Prevent keyboard input
                onPaste: (e) => e.preventDefault(), // Prevent pasting
                style: { height: "40px", caretColor: 'transparent' }, // Set the height of the input
              }}
              placeholder="Select Date"
              value={formattedValue}
            />
          );
        }}
        sx={(theme) => ({
          flexGrow: 1,
          height: "40px",
          borderRadius: "8px",
          width: "unset",
          maxWidth: "unset",
          backgroundColor: "#FBFAFC",
          "& .MuiAutocomplete-input": {
            color: "var(--charcoal-grey)",
            fontSize: theme.typography.pxToRem(12),
            fontWeight: typography.font_weight.regular,
            cursor: "pointer",
            width: "unset !important",
            minWidth: "194px !important"
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "0.5px solid #EBEAED !important",
            borderRadius: "0px !important",
          },
          "& .MuiAutocomplete-root": {
            width: "250px",
            maxWidth: "unset",
          },
        })}
      />
      <IconButton
        onClick={handleNextClick}
        sx={{
          height: "40px",
          border: "1px solid #EBEAED",
          borderRadius: "8px",
          borderTopLeftRadius: "0px",
          borderBottomLeftRadius: "0px",
        }}
        // Disable the SideButton if the last_digest_ts for the selected frequency is exactly between the start and end date, both included
        disabled={startOfDay(endDate).valueOf() >= startOfDay(localToUTC(new Date())).valueOf()}
      >
        <ArrowForwardIcon />
      </IconButton>
    </div>
  );
};

export default ReportFeedTimeFilter;
