import { Stack, Typography } from "@mui/material";
import React from "react";
import { typography } from "../../../shared/theme-constants";

const NoDataScreen = ({ open, handleClose, pageSource, isFilterSelected = false }) => {
  return (
    <Stack
      direction="column"
      gap={4}
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        style={{ width: "216px", height: "211px", objectFit: "contain" }}
        src={`${process.env.REACT_APP_CDN_BASE_URL}/images/no_data.png`}
      />

      <Typography
        sx={(theme) => ({
          fontSize: theme.typography.pxToRem(24),
          fontWeight: typography.font_weight.bolder,
          color: "rgba(17, 39, 133, 1)",
          [theme.breakpoints.down("tm")]: {
            fontSize: theme.typography.pxToRem(16),
          },
        })}
      >
        {`No ${pageSource} to display ${isFilterSelected ? "for selected filters" : "at the moment"}`}
      </Typography>
      <Stack direction="column" gap={1} sx={{ alignItems: "center" }}>
        <Typography
          sx={(theme) => ({
            fontSize: theme.typography.pxToRem(18),
            fontWeight: typography.font_weight.mediumx,
            color: "var(--black)",
            [theme.breakpoints.down("tm")]: {
              fontSize: theme.typography.pxToRem(12),
            },
          })}
        >
          {`We are currently observing your ${pageSource} and `}
        </Typography>
        <Typography
          sx={(theme) => ({
            fontSize: theme.typography.pxToRem(18),
            fontWeight: typography.font_weight.mediumx,
            color: "var(--black)",
            [theme.breakpoints.down("tm")]: {
              fontSize: theme.typography.pxToRem(12),
            },
          })}
        >
          we will notify you once its available
        </Typography>
      </Stack>
    </Stack>
  );
};

export default NoDataScreen;
