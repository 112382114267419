import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";
import "./ErrorDetailsPayload.css";

const ErrorDetailsPayload = ({ stackTrace, userDefinedCustomStyles, ...rest }) => {
  return (
    <SyntaxHighlighter
      wrapLines={true}
      language="javascript"
      style={prism}
      customStyle={{
       ...userDefinedCustomStyles
      }}
      { ...rest }
    >
      {stackTrace?.join("\n")}
    </SyntaxHighlighter>
  );
};

export default ErrorDetailsPayload;
