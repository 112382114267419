import styled from "@emotion/styled";
import { Chip } from "@mui/material";
import React from "react";
import { severityColorMapping, severityTextColorMapping } from "../../../constants/commonConstants";

const StyledChip = styled(Chip)(({ theme, padding }) => ({
  minWidth: theme.typography.pxToRem(70),
  height: theme.typography.pxToRem(30),
  borderRadius: theme.typography.pxToRem(6),
  "&& .MuiChip-label": {
    paddingLeft: padding ? `${padding} !important` : "0rem !important",
    paddingRight: padding ? `${padding} !important` : "0rem !important",
    fontFamily: "Poppins !important",
    fontSize: "11px",
    fontWeight: 400,
  },
  "&& .MuiChip-icon": {
    margin: 0,
    marginRight: "4px",
  },
}));

const EventSeverityLabels = ({label, severity}) => {
  return (
    <StyledChip
      label={label}
      style={{
        backgroundColor: severityColorMapping[severity],
        color: severityTextColorMapping[severity],
        fontSize: "14px",
      }}
      className="provider-tag"
    />
  );
};

export default EventSeverityLabels;
