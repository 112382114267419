import { Box, Button, Container, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Icon from "../components/common/Icon";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { typography } from "../shared/theme-constants";
import axios from "axios";
import { useLocalStorageState } from "../hooks";

const LOGOUT_URL = process.env.REACT_APP_BASE_URL + "logout";

const OnboardScreen = () => {
  const user = useAuthenticatedUser();
  const navigate = useNavigate();
  const [, , clearSelectedTenantFromLS] = useLocalStorageState("selectedTenant");
  const [, , clearAppliedGlobalFilter] = useLocalStorageState("currentAppliedGlobalFilters");

  const logout = async () => {
    await axios
      .get(LOGOUT_URL)
      .then((data) => {
        clearSelectedTenantFromLS();
        clearAppliedGlobalFilter();
        // reset the Heap user Identity on logout
        window?.heap?.resetIdentity();
        navigate("/login");
      })
      .catch((err) => {
        // handle logout error navigation
      });
  };

  return (
    <>
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          backgroundColor: "var(--grey-50)",
          height: useMediaQuery("(max-width:400px)") ? "100%" : "100vh",
        }}
      >
        {/* User info box */}
        <Box
          sx={{
            position: "absolute",
            top: "16px",
            right: "16px",
            backgroundColor: "var(--white)",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "transparent",
          }}
        >
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(12),
              fontWeight: typography.font_weight.regular,
              color: "var(--grey-600)",
            })}
          >
            Logged in as:
          </Typography>
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(13),
              fontWeight: typography.font_weight.mediumx,
              color: "var(--grey-800)",
            })}
          >
            {user?.email || "Not logged in"}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Button
              variant="text"
              startIcon={<Icon name="logout_gray" />}
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(12),
                fontWeight: typography.font_weight.regular,
                color: "var(--grey-600)",
                marginLeft: "-2px",
                "&:hover": {
                  backgroundColor: "#EEEEEE", // Change background on hover
                },
              })}
              onClick={logout}
            >
              Logout
            </Button>
          </Box>
        </Box>
        <Outlet></Outlet>
        <Box
          sx={(theme) => ({
            position: "fixed",
            bottom: 0,
            width: "100%",
            height: "50px",
            display: "flex",
            flexDirection: "row",
            marginLeft: "40px",
            justifyContent: "flex-start",
            [theme.breakpoints.down("lt")]: {
              marginRight: "0px",
              justifyContent: "center",
              position: "unset",
            },
          })}
        >
          <span
            style={{
              fontSize: "12px",
              fontWeight: "normal",
              color: "#6B6D77",
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              justifyContent: "left",
            }}
          >
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "left",
                justifyContent: "left",
                fontWeight: "bold",
                color: "#6B6D77",
              }}
            >
              {" "}
              Need Help? Email us:
            </span>
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "left",
                justifyContent: "left",
                color: "#6B6D77",
              }}
            >
              <a
                href="mailto:connect@outoftheblue.ai"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                }}
                cursor="pointer"
              >
                connect@outfotheblue.ai
              </a>
            </span>
          </span>
        </Box>
      </Container>
    </>
  );
};

export default OnboardScreen;
