import { Avatar, Box, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser";
import { typography } from "../../shared/theme-constants";
import { StyledActionButton } from "../common/mui-wrapper-components/Button";

const AppInstallationScreen = () => {
  const user = useAuthenticatedUser();
  const navigate = useNavigate();

  const handleSkipInstallation = () => {
    navigate(`/onboard/stripe_add_on`);
  };

  return (
    <Box
      elevation={3}
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "auto",
        width: "100vw",
        maxWidth: theme.typography.pxToRem(460),
        boxShadow: "none",
        backgroundColor: "transparent",
        [theme.breakpoints.up("tm")]: {
          width: `${theme.typography.pxToRem(460)} !important`, // Increase height on smaller screens
        },
      })}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20px",
          marginBottom: "20px",
          width: "100%",
        }}
        gap={6}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
            width: "100%",
          }}
          gap={6}
        >
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(22),
              fontWeight: typography.font_weight.mediumx,
              color: "var(--grey-900)",
              textAlign: "center",
            })}
          >
            Install Out Of The Blue App
          </Typography>
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(12),
              fontWeight: typography.font_weight.mediumx,
              color: "var(--grey-700)",
              textAlign: "center",
            })}
          >
            Out Of The Blue Shopify app makes it super easy to monitor,
            <br />
            detect, and resolve store issues using.
          </Typography>
        </Box>
        <Paper
          elevation={3}
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "auto",
            width: "100vw",
            maxWidth: theme.typography.pxToRem(460),
            boxShadow: "0 5px 20px 0 rgba(0, 0, 0, 0.15)",
            backgroundColor: "var(--white)",
            padding: "24px",
            borderRadius: "8px",
            gap: "24px",
            [theme.breakpoints.up("tm")]: {
              width: `${theme.typography.pxToRem(460)} !important`, // Increase height on smaller screens
            },
            [theme.breakpoints.down("st")]: {
              width: "100% !important", // Increase height on smaller screens
            },
          })}
        >
          <Box
            sx={{
              padding: "20px 16px",
              border: "1px solid rgba(44, 80, 255, 0.2)",
              borderRadius: "8px",
              backgroundColor: "rgba(34, 92, 235, 0.02)",
            }}
          >
            <Stack direction="column" gap={5}>
              <Stack direction="row" gap={3} alignItems="center">
                <Avatar
                  src={`${process.env.REACT_APP_CDN_BASE_URL}/images/shopify-logo.png`}
                  sx={{ width: "20px", height: "20px", objectFit: "contain" }}
                />
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(12),
                    fontWeight: typography.font_weight.mediumx,
                    color: "var(--grey-800)",
                  })}
                >
                  Must have a Shopify store
                </Typography>
              </Stack>
              <Stack direction="row" gap={3} alignItems="center">
                <Avatar
                  src={`${process.env.REACT_APP_CDN_BASE_URL}/images/user-settings.png`}
                  sx={{ width: "20px", height: "20px", objectFit: "contain" }}
                />
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(12),
                    fontWeight: typography.font_weight.mediumx,
                    color: "var(--grey-800)",
                  })}
                >
                  Must have App Install and Approve Charge authority
                </Typography>
              </Stack>
              <Stack direction="row" gap={3} alignItems="center">
                <Avatar
                  src={`${process.env.REACT_APP_CDN_BASE_URL}/images/payment-validation.png`}
                  sx={{ width: "20px", height: "20px", objectFit: "contain" }}
                />
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(12),
                    fontWeight: typography.font_weight.mediumx,
                    color: "var(--grey-800)",
                  })}
                >
                  Must have payment details stored in Shopify
                </Typography>
              </Stack>
            </Stack>
          </Box>
          <Stack
            direction="row"
            gap={1}
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              border: "1px solid #E0E0E0",
              borderRadius: "8px",
              padding: "12px",
            }}
          >
            <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={2}>
              <Avatar
                alt="Org Logo"
                src={`${process.env.REACT_APP_CDN_BASE_URL}/out_of_the_blue_logo.png`}
              />
              <Stack direction="column" gap={1} sx={{ alignItems: "flex-start" }}>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(13),
                    fontWeight: typography.font_weight.semiBold,
                    color: "var(--grey-900)",
                  })}
                >
                  Out Of The Blue
                </Typography>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(11),
                    fontWeight: typography.font_weight.regular,
                    color: "var(--grey-700)",
                  })}
                >
                  Empower your brand with better data, advanced analytics, and AI-driven automation
                </Typography>
              </Stack>
            </Box>
            <StyledActionButton
              sx={{
                backgroundColor: "var(--brand-blue)",
                height: "2rem",
                width: "4rem",
                minWidth: "3.25rem",
                fontSize: "13px",
              }}
              onClick={() =>
                window.open(
                  process.env.NODE_ENV === "production"
                    ? process.env.REACT_APP_SHOPIFY_APP_INSTALL_LINK
                    : `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SHOPIFY_APP_INSTALL_LINK}`,
                  "_self"
                )
              }
            >
              Install
            </StyledActionButton>
          </Stack>
        </Paper>
      </Box>
    </Box>
  );
};
export default AppInstallationScreen;
