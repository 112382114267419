import styled from "@emotion/styled";
import LowPriorityRoundedIcon from "@mui/icons-material/LowPriorityRounded";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { formatISO, parseISO } from "date-fns";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PIPELINE_SCHEDULE_TRANSLATION } from "../../../constants/commonConstants";
import { useAxios } from "../../../hooks/useAxios";
import { useSelectedTenant } from "../../../hooks/useSelectedTenant";
import { colors, muiTheme, typography } from "../../../shared/theme-constants";
import { generateDifferenceInTimeString } from "../../../utils/dateUtils";
import Icon from "../../common/Icon";
import ListLoader from "../../common/ListLoader";
import LiveIndicator from "../../common/LiveIndicator";
import EventSummaryCard from "./EventSummaryCard";
import { EVENTS_FEED_SUMMARY_FILTERS } from "../../../constants/eventConstants";
import { useNavigate, useSearchParams } from "react-router-dom";

const StyledToggleButton = styled(ToggleButton)(({ theme, minheight }) => ({
  minHeight: theme.typography.pxToRem(minheight || 125),
  width: 'calc((100% / 3) - 14px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: "6px",
  padding: "unset",
  backgroundColor: '#fff', // Adjust this color as needed
  border: "1px solid #fff",
  '&.Mui-selected': {
    backgroundColor: '#fff', // Adjust this color as needed
    border: "1px solid rgba(30, 70, 239, 1) !important",
  },
  '&.Mui-disabled': {
    border: "1px solid #fff !important",
    pointerEvents: "auto" // It is applied because in case of disabled buttons pointer events is none and it doesn't fire the tooltip events.
  },
  '&.Mui-selected:hover': {
    backgroundColor: '#d0d0d0', // Adjust this color as needed
  },
  [theme.breakpoints.down("st")]: {
    minHeight: "unset",
  },
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  width: '100%',
  justifyContent: 'space-between',
  border: "1px solid #fff",
  '& .MuiToggleButtonGroup-grouped:not(:last-of-type)': {
    borderTopRightRadius: '6px',
    borderBottomRightRadius: '6px',
  },
  '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
    borderTopLeftRadius: '6px',
    borderBottomLeftRadius: '6px',
    borderLeft: "1px solid #fff"
  },
}));

const CardMessage = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  textAlign: "center",
  fontStyle: "normal",
  fontWeight: typography.font_weight.light,
  lineHeight: theme.typography.pxToRem(20),
  color: colors.title,
}));

const StyledEventSummaryCard = styled(Card)(({ theme, minheight }) => ({
  minHeight: theme.typography.pxToRem(minheight || 125),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  [theme.breakpoints.down("st")]: {
    minHeight: "unset",
  },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  flexGrow: 1,
  paddingBottom: "16px",
  "&:last-child": {
    paddingBottom: "16px",
  },
}));

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  [theme.breakpoints.down("st")]: {
    paddingBottom: "8px",
    paddingTop: "8px",
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  minWidth: theme.typography.pxToRem(48),
  height: theme.typography.pxToRem(24),
  borderRadius: theme.typography.pxToRem(5),
  "& .MuiChip-label": {
    // Override label styles here
    paddingLeft: "6px",
  },
}));

const CardState = ({ status, loadingElement, errorElement, children }) => {
  if (status === "error") {
    return errorElement;
  }
  if (status === "loading") {
    return loadingElement;
  }
  if (status === "success") {
    return children;
  }

  return null;
};

const MAX_EVENTS_TO_SHOW_ON_SUMMARY = 3;

const EventSummary = ({ startTime, endTime, pipelineSchedule, pageType }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tenantGlobalFilter = useSelector((state) => state.tenantGlobalFilter?.appliedFilter);
  const selectedTenant = useSelectedTenant();
  const summaryFilters = useSelector((state) => state.eventsFeedFilters.summaryFilters);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: eventsSummaryData,
    status: status,
    isLoading: loading,
  } = useAxios("events/summary", {
    params: {
      dim_name: tenantGlobalFilter?.dimension_name,
      dim_val: tenantGlobalFilter?.dimension_value,
      tenant_id: selectedTenant,
      start_time: formatISO(startTime, { representation: "date" }),
      end_time: formatISO(endTime, { representation: "date" }),
      frequency: pipelineSchedule,
    },
  });

  const severityPercentChange =
    eventsSummaryData?.data?.severity?.change_from_last_month?.percentage;

  const isMobileScreen = useMediaQuery(muiTheme.breakpoints.down("tm"));

  const renderEstimatedImpact = () => {
    return (
      <Grid item tm={3} sm={7}>
        <StyledEventSummaryCard
          sx={{
            height: "100%",
            cursor: pageType === "digest" ? "pointer" : "default",
            backgroundColor:
              eventsSummaryData?.data?.impact?.monetary?.value === 0
                ? "rgba(212, 237, 218, 1)"
                : "#fff",
          }}
          onClick={() => {
            if (pageType === "digest") {
              return navigate("/events");
            }
            return;
          }}
        >
          <CardState
            status={status}
            errorElement={<CardMessage>Encountered an error while fetching stories</CardMessage>}
            sx={{ minHeight: "100%", margin: 2 }}
            loadingElement={
              <ListLoader sx={(theme) => ({ margin: 10 })} count={1} height={200} paddingY={10} />
            }
          >
            <StyledCardHeader
              title={
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(14),
                    color: "rgba(61, 61, 61, 1)",
                    fontWeight: typography.font_weight.mediumx,
                    [theme.breakpoints.down("st")]: {
                      fontSize: theme.typography.pxToRem(10),
                    },
                  })}
                >
                  Total Estimated Impact
                </Typography>
              }
            />
            <StyledCardContent
              sx={{ display: "flex", justifyContent: "space-between", paddingTop: "0px" }}
            >
              <Stack justifyContent="space-between" sx={{ width: "100%" }}>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(28),
                    color: "rgba(61, 61, 61, 1)",
                    fontWeight: typography.font_weight.bold,
                    [theme.breakpoints.down("st")]: {
                      fontSize: theme.typography.pxToRem(24),
                    },
                  })}
                >
                  {(eventsSummaryData?.data?.severity?.High?.count === 0 &&
                    eventsSummaryData?.data?.severity?.Medium?.count > 0) ||
                  eventsSummaryData?.data?.impact === undefined
                    ? "Inconclusive"
                    : eventsSummaryData?.data?.impact?.monetary?.formatted_value}
                </Typography>
                {(eventsSummaryData?.data?.severity?.High?.count === 0 &&
                  eventsSummaryData?.data?.severity?.Medium?.count > 0) ||
                eventsSummaryData?.data?.impact === undefined ? (
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(10),
                      color: "rgba(61, 61, 61, 1)",
                      fontWeight: typography.font_weight.regular,
                    })}
                  >
                    We’re working diligently to quantify impact
                  </Typography>
                ) : (
                  eventsSummaryData?.data?.impact?.monetary?.delta_percentage !== null ? (
                    <Stack direction="row" sx={{ alignItems: "center", display: eventsSummaryData?.data?.impact?.monetary?.delta_percentage === null ? 'none' : 'flex' }}>
                    <StyledChip
                      sx={{
                        pl: 3,
                        background:
                        eventsSummaryData?.data?.impact?.monetary?.delta_percentage < 0
                            ? colors.green["150"]
                            : colors.red["150"],
                      }}
                      icon={
                        <Icon
                          name={
                            eventsSummaryData?.data?.impact?.monetary?.delta_percentage > 0
                              ? "arrow-negative-up"
                              : "arrow-positive-down"
                          }
                          size="8px"
                        />
                      }
                      label={
                        <Typography
                          sx={(theme) => ({
                            fontSize: theme.typography.pxToRem(12),
                            color:
                              eventsSummaryData?.data?.impact?.monetary?.delta_percentage < 0
                                ? colors.SEVERITY.GREEN.medium
                                : "var(--red-3)",
                          })}
                        >
                          {eventsSummaryData?.data?.impact?.monetary?.formatted_delta_percentage}
                        </Typography>
                      }
                    />
                    <Typography
                      sx={(theme) => ({
                        fontSize: theme.typography.pxToRem(12),
                        color: "rgba(61, 61, 61, 1)",
                        ml: 1,
                      })}
                    >
                      from last {PIPELINE_SCHEDULE_TRANSLATION[pipelineSchedule]}
                    </Typography>
                  </Stack>
                  ) : null
                )}
              </Stack>
            </StyledCardContent>
          </CardState>
        </StyledEventSummaryCard>
      </Grid>
    );
  };

  const handleDigestNavigation = (filterType = null, filterValue = null) => {
    const updatedFilters = { ...summaryFilters };
    if (filterType) {
      updatedFilters[filterType] = filterValue;
    }
    const updatedParams = new URLSearchParams();
    // Convert the summaryFilters object to query parameters
    for (const [key, value] of Object.entries(updatedFilters)) {
      if (key === "provider_uuid") {
        updatedParams.set(key, encodeURIComponent(JSON.stringify(value)));
      } else {
        updatedParams.set(key, value);
      }
    }
    dispatch({
      type: EVENTS_FEED_SUMMARY_FILTERS,
      payload: { value: filterValue, type: filterType },
    });
    navigate(`/events?${updatedParams.toString()}`);
  };

  // On selection of any filter in summary cards, below function will dispatch  call with filter name and its value and it will be stored accordingly.
  const handleSummaryFilters = (event, filterType, filterValue) => {
    event.stopPropagation();
    if (pageType === "digest") {
      return handleDigestNavigation(filterType, filterValue);
    }
    // Create a new URLSearchParams object from the current search parameters
    const updatedParams = new URLSearchParams(searchParams);
    if (filterValue === null) {
      // Remove the filter from the query parameters if the value is null
      updatedParams.delete(filterType);
    } else {
      // Otherwise, set or update the filter with its value
      if (filterType === "provider_uuid") {
        updatedParams.set("provider_uuid", JSON.stringify(filterValue));
      } else {
        updatedParams.set(filterType, filterValue);
      }
    }
    // Update the URL with the modified search parameters
    setSearchParams(updatedParams);
    dispatch({
      type: EVENTS_FEED_SUMMARY_FILTERS,
      payload: { value: filterValue, type: filterType },
    });
  };

  return (
    <>
      <EventSummaryCard
        sx={(theme) => ({
          width: "100%",
          padding: pageType === "digest" ? 0 : "16px",
          [theme.breakpoints.down("st")]: {
            display: "contents",
          },
        })}
      >
        <Grid item container tm={12} spacing={2}>
          <Grid item tm={4} sm={12}>
            <StyledEventSummaryCard
              sx={{ height: "100%", cursor: pageType === "digest" ? "pointer" : "default" }}
              onClick={(event) => {
                event.stopPropagation();
                if (pageType === "digest") {
                  return handleDigestNavigation();
                }
                return;
              }}
            >
              <CardState
                status={status}
                errorElement={
                  <CardMessage>Encountered an error while fetching stories</CardMessage>
                }
                sx={{ minHeight: "100%" }}
                loadingElement={<ListLoader count={1} height={200} paddingY={10} />}
              >
                <StyledCardHeader
                  title={
                    <Stack
                      direction="column"
                      gap={1}
                      sx={{ alignItems: "flex-start", justifyContent: "space-between" }}
                    >
                      <Typography
                        sx={(theme) => ({
                          fontSize: theme.typography.pxToRem(14),
                          color: "rgba(61, 61, 61, 1)",
                          fontWeight: typography.font_weight.mediumx,
                        })}
                      >
                        Events Impacting Business
                      </Typography>
                    </Stack>
                  }
                  sx={{ pb: 0 }}
                />

                <StyledCardContent sx={{ display: "flex", justifyContent: "space-between" }}>
                  <StyledToggleButtonGroup
                    value={pageType === 'digest' ? null : summaryFilters.severity} // In case of digest page, we are keeping value as null so that blue border when defualt filter is selected will not come.
                    exclusive
                    onChange={(event, severityType) =>
                      handleSummaryFilters(event, "severity", severityType)
                    }
                    sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
                  >
                    <StyledToggleButton value="High" minheight={69}>
                      <Tooltip title="High Severity" placement="top-end">
                        <StyledEventSummaryCard
                          minheight={69}
                          sx={{
                            width: "100%",
                            height: "100%",
                            backgroundColor:
                              eventsSummaryData?.data?.severity?.High?.count > 0
                                ? "rgba(243, 214, 206, 1)"
                                : "#fff",
                          }}
                        >
                          <Stack
                            alignItems="center"
                            sx={(theme) => ({
                              [theme.breakpoints.down("st")]: {
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%",
                                padding: "0 8px",
                              },
                            })}
                          >
                            <PriorityHighRoundedIcon
                              style={{ color: "var(--red-3)", fontSize: "1.5rem" }}
                            />
                            <Typography
                              sx={(theme) => ({
                                fontSize: theme.typography.pxToRem(20),
                                color: "rgba(61, 61, 61, 1)",
                                fontWeight: typography.font_weight.semiBold,
                              })}
                            >
                              {eventsSummaryData?.data?.severity?.High?.count}
                            </Typography>
                          </Stack>
                        </StyledEventSummaryCard>
                      </Tooltip>
                    </StyledToggleButton>
                    <StyledToggleButton value="Medium" minheight={69}>
                      <Tooltip title="Medium Severity" placement="top-end">
                        <StyledEventSummaryCard
                          sx={{
                            width: "100%",
                            height: "100%",
                            backgroundColor:
                              eventsSummaryData?.data?.severity?.High?.count === 0 &&
                              eventsSummaryData?.data?.severity?.Medium?.count > 0
                                ? "rgba(252, 243, 217, 1)"
                                : "#fff",
                          }}
                          minheight={69}
                        >
                          <Stack
                            alignItems="center"
                            sx={(theme) => ({
                              [theme.breakpoints.down("st")]: {
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%",
                                padding: "0 8px",
                              },
                            })}
                          >
                            <WarningAmberRoundedIcon
                              style={{ color: "#CC6700", fontSize: "1.5rem" }}
                            />
                            <Typography
                              sx={(theme) => ({
                                fontSize: theme.typography.pxToRem(20),
                                color: "rgba(61, 61, 61, 1)",
                                fontWeight: typography.font_weight.semiBold,
                              })}
                            >
                              {eventsSummaryData?.data?.severity?.Medium?.count}
                            </Typography>
                          </Stack>
                        </StyledEventSummaryCard>
                      </Tooltip>
                    </StyledToggleButton>
                    <StyledToggleButton value="Low" minheight={69}>
                      <Tooltip title={"Low Severity"} placement="top-end">
                        <StyledEventSummaryCard
                          minheight={69}
                          sx={{
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Stack
                            alignItems="center"
                            sx={(theme) => ({
                              [theme.breakpoints.down("st")]: {
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%",
                                padding: "0 8px",
                              },
                            })}
                          >
                            <LowPriorityRoundedIcon
                              style={{ color: "#43A45C", fontSize: "1.5rem" }}
                            />
                            <Typography
                              sx={(theme) => ({
                                fontSize: theme.typography.pxToRem(20),
                                color: "rgba(61, 61, 61, 1)",
                                fontWeight: typography.font_weight.semiBold,
                              })}
                            >
                              {eventsSummaryData?.data?.severity?.Low?.count}
                            </Typography>
                          </Stack>
                        </StyledEventSummaryCard>
                      </Tooltip>
                    </StyledToggleButton>
                  </StyledToggleButtonGroup>
                </StyledCardContent>
              </CardState>
            </StyledEventSummaryCard>
          </Grid>
          {eventsSummaryData?.data?.status?.Active?.count === 0 &&
          eventsSummaryData?.data?.status?.Closed?.count === 0 ? (
            <Grid
              item
              tm={8}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <StyledEventSummaryCard
                sx={(theme) => ({
                  width: "100%",
                  padding: "16px",
                  [theme.breakpoints.up("st")]: {
                    display: "contents",
                  },
                })}
              >
                <Stack direction="row" sx={{ alignItems: "center", justifyContent: "center" }}>
                  <Box sx={{ display: "flex" }}>
                    <img
                      style={{
                        width: isMobileScreen ? "21px" : "56px",
                        height: isMobileScreen ? "21px" : "56px",
                        objectFit: "contain",
                      }}
                      src={`${process.env.REACT_APP_CDN_BASE_URL}/images/success.png`}
                    />
                  </Box>
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(16),
                      color: "rgba(61, 61, 61, 1)",
                      fontWeight: typography.font_weight.bold,
                      ml: 2,
                      [theme.breakpoints.down("st")]: {
                        fontSize: theme.typography.pxToRem(12),
                      },
                    })}
                  >
                    No errors found impacting your business
                  </Typography>
                </Stack>
              </StyledEventSummaryCard>
            </Grid>
          ) : (
            <>
              <Grid item tm={2} sm={5}>
                <StyledToggleButtonGroup
                  value={pageType === 'digest' ? null : summaryFilters.status} // In case of digest page, we are keeping value as null so that blue border when defualt filter is selected will not come.
                  exclusive
                  onChange={(event, statusType) =>
                    handleSummaryFilters(event, "status", statusType)
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    border: "unset",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <StyledToggleButton
                    value="Live"
                    minheight={80}
                    style={{
                      width: "100%",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      flexGrow: 9,
                      border: "0px solid #fff",
                    }}
                  >
                    <StyledEventSummaryCard
                      minheight={80}
                      sx={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <CardState
                        status={status}
                        errorElement={
                          <CardMessage>Encountered an error while fetching stories</CardMessage>
                        }
                        sx={{ minHeight: "100%", width: "100%" }}
                        loadingElement={<ListLoader count={1} height={200} paddingY={10} />}
                      >
                        <StyledCardContent
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            height: "100%",
                            padding: 4,
                          }}
                        >
                          <LiveIndicator
                            sx={(theme) => ({ fontSize: theme.typography.pxToRem(16), ml: -3.5 })}
                          />
                          <Typography
                            sx={(theme) => ({
                              fontSize: theme.typography.pxToRem(24),
                              color: "rgba(61, 61, 61, 1)",
                              fontWeight: typography.font_weight.bold,
                            })}
                          >
                            {eventsSummaryData?.data?.status?.Active?.formatted_count}
                          </Typography>
                        </StyledCardContent>
                      </CardState>
                    </StyledEventSummaryCard>
                  </StyledToggleButton>
                  <StyledToggleButton
                    value="Closed"
                    style={{
                      width: "100%",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      flexGrow: 1,
                      border: "0px solid #fff",
                    }}
                    minheight={40}
                  >
                    <StyledEventSummaryCard
                      minheight={40}
                      sx={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <CardState
                        status={status}
                        errorElement={
                          <CardMessage>Encountered an error while fetching stories</CardMessage>
                        }
                        sx={{ minHeight: "100%" }}
                        loadingElement={<ListLoader count={1} height={200} paddingY={10} />}
                      >
                        <StyledCardContent
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            height: "100%",
                            padding: 4,
                          }}
                        >
                          <Typography
                            sx={(theme) => ({
                              fontSize: theme.typography.pxToRem(12),
                              color: "rgba(61, 61, 61, 1)",
                            })}
                          >
                            Closed
                          </Typography>
                          <Typography
                            sx={(theme) => ({
                              fontSize: theme.typography.pxToRem(14),
                              color: "rgba(61, 61, 61, 1)",
                              fontWeight: typography.font_weight.semiBold,
                            })}
                          >
                            {eventsSummaryData?.data?.status?.Closed?.formatted_count}
                          </Typography>
                        </StyledCardContent>
                      </CardState>
                    </StyledEventSummaryCard>
                  </StyledToggleButton>
                </StyledToggleButtonGroup>
              </Grid>
              {isMobileScreen ? renderEstimatedImpact() : null}
              <Grid item tm={3} sm={12}>
                <StyledEventSummaryCard
                  sx={{ height: "100%", cursor: pageType === "digest" ? "pointer" : "default" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    if (pageType === "digest") {
                      return handleDigestNavigation();
                    }
                    return;
                  }}
                >
                  <CardState
                    status={status}
                    errorElement={
                      <CardMessage>Encountered an error while fetching stories</CardMessage>
                    }
                    sx={{ minHeight: "100%" }}
                    loadingElement={<ListLoader count={1} height={200} paddingY={10} />}
                  >
                    <StyledCardHeader
                      title={
                        <Typography
                          sx={(theme) => ({
                            fontSize: theme.typography.pxToRem(14),
                            color: "rgba(61, 61, 61, 1)",
                            fontWeight: typography.font_weight.mediumx,
                          })}
                        >
                          Affected Providers
                        </Typography>
                      }
                    />
                    <StyledCardContent
                      sx={{ display: "flex", justifyContent: "flex-start", gap: 4 }}
                    >
                      <StyledToggleButtonGroup
                        value={pageType === 'digest' ? null : summaryFilters.provider_uuid?.id} // In case of digest page, we are keeping value as null so that blue border when defualt filter is selected will not come.
                        exclusive
                        onChange={(event, selectedProvider) => {
                          const selectedProviderDetails =
                            eventsSummaryData?.data?.top_providers.find(
                              (provider) => provider.provider_uuid === selectedProvider
                            );
                          if (selectedProviderDetails) {
                            handleSummaryFilters(event, "provider_uuid", {
                              id: selectedProviderDetails.provider_uuid,
                              name: selectedProviderDetails.provider_display_name,
                            });
                          } else {
                            handleSummaryFilters(event, "provider_uuid", null);
                          }
                        }}
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          border: "unset",
                        }}
                      >
                        {eventsSummaryData?.data?.top_providers
                          ?.slice(0, MAX_EVENTS_TO_SHOW_ON_SUMMARY)
                          .map((providerDetails, index) => (
                            <StyledToggleButton
                              key={index}
                              value={providerDetails?.provider_uuid}
                              minheight={69}
                              style={{
                                width: "calc((100% / 3) - 6px) !important",
                                borderWidth: "0px",
                              }}
                            >
                              <Tooltip
                                key={index}
                                title={providerDetails?.provider_display_name}
                                placement="top-end"
                              >
                                <StyledEventSummaryCard
                                  minheight={69}
                                  sx={(theme) => ({
                                    width: "100%",
                                    height: "100%",
                                  })}
                                >
                                  <Stack
                                    sx={(theme) => ({
                                      alignItems: "center",
                                      [theme.breakpoints.down("st")]: {
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        padding: "4px 8px",
                                      },
                                    })}
                                  >
                                    <img
                                      style={{ width: 30, height: 30, objectFit: "contain" }}
                                      src={providerDetails?.provider_logo_url}
                                    />
                                    <Typography
                                      sx={(theme) => ({
                                        fontSize: theme.typography.pxToRem(14),
                                        color: "rgba(61, 61, 61, 1)",
                                        fontWeight: typography.font_weight.semiBold,
                                      })}
                                    >
                                      {providerDetails?.formatted_count}
                                    </Typography>
                                  </Stack>
                                </StyledEventSummaryCard>
                              </Tooltip>
                            </StyledToggleButton>
                          ))}
                      </StyledToggleButtonGroup>
                    </StyledCardContent>
                  </CardState>
                </StyledEventSummaryCard>
              </Grid>
              {isMobileScreen === false ? renderEstimatedImpact() : null}
            </>
          )}
        </Grid>
      </EventSummaryCard>
      {pageType !== "digest" ? (
        <Box
          sx={(theme) => ({
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            [theme.breakpoints.down("st")]: { justifyContent: "center" },
          })}
        >
          <Stack direction="row" gap={2}>
            <Stack direction="row" gap={1}>
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(12),
                  color: "rgba(61, 61, 61, 1)",
                  fontWeight: typography.font_weight.light,
                  [theme.breakpoints.down("st")]: {
                    fontSize: theme.typography.pxToRem(8),
                  },
                })}
              >
                Last Processed :
              </Typography>
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(12),
                  color: "rgba(61, 61, 61, 1)",
                  fontWeight: typography.font_weight.mediumx,
                  [theme.breakpoints.down("st")]: {
                    fontSize: theme.typography.pxToRem(8),
                  },
                })}
              >
                {eventsSummaryData?.data?.last_event_ts
                  ? generateDifferenceInTimeString(parseISO(eventsSummaryData?.data?.last_event_ts))
                  : "-"}
              </Typography>
            </Stack>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ backgroundColor: "var(--black)", height: "13px", alignSelf: "center" }}
            />
            <Stack direction="row" gap={1}>
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(12),
                  color: "rgba(61, 61, 61, 1)",
                  fontWeight: typography.font_weight.light,
                  [theme.breakpoints.down("st")]: {
                    fontSize: theme.typography.pxToRem(8),
                  },
                })}
              >
                Total Errors Received :
              </Typography>
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(12),
                  color: "rgba(61, 61, 61, 1)",
                  fontWeight: typography.font_weight.mediumx,
                  [theme.breakpoints.down("st")]: {
                    fontSize: theme.typography.pxToRem(8),
                  },
                })}
              >
                {eventsSummaryData?.data?.total_events?.formatted_count}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};
export default EventSummary;
