import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateAPIKey } from "../../../actions/preferenceActions";
import { GENERATE_API_REQUEST_RESET } from "../../../constants/preferenceConstants";
import { SET_SNACKBAR } from "../../../reducers/notificationSnackbarReducer";
import { typography } from "../../../shared/theme-constants";
import { isEmpty } from "../../../utils/is";
import {
  StyledActionButton,
} from "../../common/mui-wrapper-components/Button";
import { Loading } from "../../common/styled-components";
import { useSelectedTenant } from "../../../hooks/useSelectedTenant";
import { useLocalStorageState } from "../../../hooks";

const GenerateApikeyDialog = ({ open, handleClose }) => {
  const [keyLabel, setKeyLabel] = useState();
  const [isCopied, setIsCopied] = useState(false);
  const dispatch = useDispatch();
  const selectedTenant = useSelectedTenant();
  const [globalFilterValue] = useLocalStorageState("currentAppliedGlobalFilters");

  const { loading: apiKeyStatus, apiKeyDetails } = useSelector(
    (state) => state.apiKeyReducer
  );

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(apiKeyDetails?.token)
      .then(() => {
        dispatch({
          type: SET_SNACKBAR,
          snackbarOpen: true,
          snackbarMessage: "API Key copied!",
          snackbarType: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
        setIsCopied(true);
      })
      .catch((error) => {
        setIsCopied(false);
      });
  };

  const getDialogContent = () => {
    if (!isEmpty(apiKeyDetails)) {
      return (
        <Stack direction="column" gap={1} sx={{ alignItems: "flex-start" }}>
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(12),
              fontWeight: typography.font_weight.light,
            })}
          >
            {apiKeyDetails?.name}
          </Typography>
          <Stack
            direction="row"
            gap={1}
            sx={{ alignItems: "flex-start", width: "100%" }}
          >
            <TextField
              type="text"
              value={apiKeyDetails?.token}
              InputProps={{
                readOnly: true,
              }}
              sx={{
                width: "95%",
                "& .MuiOutlinedInput-root": {
                  height: "40px",
                },
                "& .MuiOutlinedInput-root.Mui-disabled": {
                  color: "rgba(0, 0, 0, 1)",
                  backgroundColor: "rgba(235, 235, 235, 1)",
                },
              }}
              disabled
            />
            <Button
              variant="outlined"
              startIcon={
                <img
                  src={`${process.env.REACT_APP_CDN_BASE_URL}/images/copy.png`}
                  alt="Copy Icon"
                />
              }
              onClick={copyToClipboard}
              sx={{
                height: "40px",
                width: "40px",
                minWidth: "unset",
                border: "1px solid rgba(196, 196, 196, 1)",
                "& .MuiButton-startIcon": {
                  margin: "unset",
                },
              }}
            />
          </Stack>
          <Alert
            icon={false}
            severity="success"
            style={{ width: '100%' }}
            sx={(theme) => ({
              backgroundColor: "rgba(245, 245, 245, 1)",
              color: "rgba(102, 102, 102, 1)",
              fontSize: theme.typography.pxToRem(12),
              mt: 1,
            })}
          >
            Please note that the API key shown here will be visible in this
            popup only and cannot be retrieved later.
          </Alert>
        </Stack>
      );
    } else {
      return (
        <Stack direction="column" gap={1} sx={{ alignItems: "flex-start" }}>
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(12),
              fontWeight: typography.font_weight.light,
            })}
          >
            Name your key
          </Typography>
          <TextField
            type="text"
            sx={{
              width: "100%",
            }}
            size="small"
            value={keyLabel}
            onChange={(event) => setKeyLabel(event.target.value)}
          />
        </Stack>
      );
    }
  };

  const handleGenerateApiKey = () => {
    if (isEmpty(keyLabel)) {
      return dispatch({
        type: SET_SNACKBAR,
        snackbarOpen: true,
        snackbarMessage: "Please enter key label.",
        snackbarType: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
    // Default Scope is "all"
    const postData = {
      name: keyLabel,
      tenant_id: selectedTenant,
      scope: "all",
    };
    // Check globalFilterValue for dimension_name and dimension_value
    if (globalFilterValue?.dimension_name && globalFilterValue?.dimension_value) {
      // Update scope to "dimension_name:dimension_value"
      postData.scope = `${globalFilterValue?.dimension_name}:${globalFilterValue?.dimension_value}`;
    }
    dispatch(generateAPIKey(postData));
  };

  const handleCloseDialog = () => {
    setKeyLabel("");
    setIsCopied(false);
    dispatch({ type: GENERATE_API_REQUEST_RESET });
    handleClose();
  };

  return (
      <Dialog
        open={open}
        maxWidth={false}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "525px",
            },
          },
        }}
      >
        <DialogTitle
          sx={(theme) => ({
            textAlign: "center",
            fontSize: theme.typography.pxToRem(16),
          })}
        >
          API Key Generation
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: "rgba(84, 92, 126, 1)",
          })}
          disabled={!isCopied && !isEmpty(apiKeyDetails)}
        >
          <CloseIcon />
        </IconButton>
        <Divider />
        <DialogContent sx={(theme) => ({ padding: theme.spacing(6) })}>
          {apiKeyStatus ? (
            <Stack
              direction="column"
              gap={1}
              sx={{ alignItems: "center", height: "100%" }}
            >
              <Loading margin="24px" />
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(12),
                  fontWeight: typography.font_weight.light,
                })}
              >
                We’re generating API Key for you.
              </Typography>
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(12),
                  fontWeight: typography.font_weight.light,
                })}
              >
                Please wait for a while.
              </Typography>
            </Stack>
          ) : (
            getDialogContent()
          )}
        </DialogContent>
        {!apiKeyStatus ? (
          isEmpty(apiKeyDetails) ? (
            <DialogActions
            sx={(theme) => ({
              justifyContent: "center",
              padding: theme.spacing(6),
            })}
          >
            <StyledActionButton onClick={handleGenerateApiKey}>
              Generate API Key
            </StyledActionButton>
          </DialogActions>
          ) : (
            <DialogActions
            sx={(theme) => ({
              justifyContent: "center",
              padding: theme.spacing(6),
            })}
          >
            <StyledActionButton
              onClick={handleCloseDialog}
              disabled={!isCopied}
            >
              Close
            </StyledActionButton>
          </DialogActions>
          )
        ): null}
      </Dialog>
  );
};

export default GenerateApikeyDialog;
