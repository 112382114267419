import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetGroupInsights } from "../actions/groupInsightActions";
import { TEAM_INSIGHT_ACTION_CANCEL } from "../constants/teamConstants";
import {
  ChartContainer,
  Container,
  FeedTitle,
} from "./common/styled-components";
import { ActionControlContainer } from "./common/styled-components/ActionControl.styled";
import TeamActionsControl from "./common/team-actions/TeamActionsControl";

const FeedContainer = ({ handleClickActionIcon, backgroundColor, containerPadding, containerWidth, children }) => {
  const dispatch = useDispatch();
  const showTeamControl = useSelector(
    (state) => state.teamInsightList.showTeamControl
  );
  const menuItem = useSelector((state) => state.drawer.menuItem);

  /**
   * This function will be called whenever the Feed (which is our main component) component is unmounted.
   * Basically to handle the back and forth flow, wherein user selects back and navigate to /customers and
   * then selects a different tenant to land on Feed.
   * This is called inside the one-time useEffect cleanup function below.
   */
  const resetReduxState = useCallback(() => {
    dispatch(resetGroupInsights());
    dispatch({ type: TEAM_INSIGHT_ACTION_CANCEL, selectedInsights: [] });
  }, [dispatch]);

  useEffect(() => {
    // Reset the state when this component gets unmounted, instead of resetting the states while loading the Feed for the first time.
    // An incorrect behaviour was obeserved if we reset the state on first load, wherein the call to insights API was done twice,
    // once with the previous state (say, Important) and once with the state that is just reset (i.e. Open).
    return () => {
      resetReduxState();
    };
  }, [resetReduxState]);

  const isMenuOpen = menuItem === "Open";

  return (
    <Container bgColor={backgroundColor} containerPadding={containerPadding}>
      {showTeamControl && (
        <ActionControlContainer top={isMenuOpen ? "75px" : "110px"} />
      )}

      <ChartContainer
        padding={containerPadding}
        width={containerWidth}
        marginTop={
          isMenuOpen && showTeamControl
            ? "150px"
            : isMenuOpen && !showTeamControl
            ? 0
            : !isMenuOpen && showTeamControl
            ? "190px"
            : "55px"
        }
      >
        {!isMenuOpen && <FeedTitle>{menuItem}</FeedTitle>}
        {showTeamControl && (
          <TeamActionsControl
            handleClickTeamActionIcon={handleClickActionIcon}
          />
        )}

        {children}
      </ChartContainer>
    </Container>
  );
};

export default FeedContainer;
