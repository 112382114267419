import axios from "axios";

import { SET_SNACKBAR } from "../reducers/notificationSnackbarReducer";

const url = process.env.REACT_APP_BASE_URL;

export const updateTenantDetails = (postData, tenantUuid) => async (dispatch) => {
  try {
    const { data } = await axios.patch(`${url}tenants/${tenantUuid}`, postData);
    dispatch({
      type: SET_SNACKBAR,
      snackbarOpen: true,
      snackbarMessage: "Workspace created successfully",
      snackbarType: "success",
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
    });
     return data?.data;
  } catch (error) {
    dispatch({
      type: SET_SNACKBAR,
      snackbarOpen: true,
      snackbarMessage: "An Error occurred while creating workspace.",
      snackbarType: "error",
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
    });
    throw error.response;
  }
};

export const joinTenant = (postData, tenantUuid) => async (dispatch) => {
  try {
    const { data } = await axios.post(`${url}tenants/${tenantUuid}/join`, postData);
    dispatch({
      type: SET_SNACKBAR,
      snackbarOpen: true,
      snackbarMessage: "Team joined successfully",
      snackbarType: "success",
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
    });
     return data;
  } catch (error) {
    dispatch({
      type: SET_SNACKBAR,
      snackbarOpen: true,
      snackbarMessage: "An Error occurred while joining the team.",
      snackbarType: "error",
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
    });
    throw error.response;
  }
};

export const updatePersonaDetails = (postData, userUuid) => async (dispatch) => {
  try {
    const { data } = await axios.patch(`${url}users/${userUuid}`, postData);
    dispatch({
      type: SET_SNACKBAR,
      snackbarOpen: true,
      snackbarMessage: "Details updated successfully",
      snackbarType: "success",
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
    });
     return data?.data;
  } catch (error) {
    dispatch({
      type: SET_SNACKBAR,
      snackbarOpen: true,
      snackbarMessage: "An Error occurred while updating your details.",
      snackbarType: "error",
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
    });
    throw error.response;
  }
};
