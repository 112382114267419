import { ThemeProvider } from "@mui/material/styles";
import { withProfiler } from "@sentry/react";
import React, { createContext } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { ThemeProvider as SCThemeProvider } from "styled-components";

import "./App.css";

import PersonaCreationScreen from "../../src/components/Onboarding/PersonaSelection";
import { getIsUserLoggedIn } from "../actions/userActions";
import { MASTER_TENANT } from "../constants/tenantConstants";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import EventGroupCollaborateScreen from "../screens/EventGroupCollaborateScreen";
import GroupCollaborateScreen from "../screens/GroupCollaborateScreen";
import GroupInsightScreen from "../screens/GroupInsightScreen";
import InsightCollaborateScreen from "../screens/InsightCollaborateScreen";
import MetricDetailsScreen from "../screens/MetricDetailsScreen";
import OnboardScreen from "../screens/OnboardScreen";
import SingleInsightScreen from "../screens/SingleInsightScreen";
import { COLLABORATE } from "../shared/constants/routes";
import { errorMessage, errorTitle } from "../shared/intl/error-messages";
import { muiTheme } from "../shared/theme-constants";
import { initializeGTM } from "../utils/gtmHelper";
import AppLayout from "./AppLayout";
import AppInstallationScreen from "./Onboarding/AppInstallation";
import StripeBillingScreen from "./Onboarding/StripeBilling";
import JoinWorkspaceScreen from "./Onboarding/JoinWorkspace";
import StartFreeTrial from "./Onboarding/StartFreeTrial";
import TenantDetailsScreen from "./Onboarding/TenantDetails";
import CsvFileUpload from "./common/CsvFileUpload";
import { ModalRouteContainer } from "./common/ModalRouteContainer";
import ScreenError from "./common/error-components/ScreenError";
import { LoaderInCenter } from "./common/styled-components";
import GlobalStyles from "./common/styled-components/Global";
import { theme } from "./common/styled-components/theme";
import ConfigureMetrics from "./onboard/ConfigureMetrics";
import FinishOnboarding from "./onboard/FinishOnboarding";
import SelectDataSources from "./onboard/SelectDataSources";
import SetPreferences from "./onboard/SetPreferences";
import SyncingData from "./onboard/SyncingData";
import CustomersScreen from "../screens/CustomersScreen";
import DataExplorerScreen from "../screens/DataExplorerScreen";
import DatasourcesStatusScreen from "../screens/DatasourcesStatusScreen";
import DigestScreen from "../screens/DigestScreen";
import EventGroupScreen from "../screens/EventGroupScreen";
import FeedScreen from "../screens/FeedScreen";
import MetricListScreen from "../screens/MetricListScreen";
import PreferenceScreen from "../screens/PreferenceScreen";
import RedocScreen from "../screens/RedocScreen";
import LoginScreen from "../screens/LoginScreen";
import SignupScreen from "../screens/SignUpScreen";

export const FeatureContext = createContext();
export const queryClient = new QueryClient();
const gtmID = process.env.REACT_APP_GTM_ID;

const featureContext = {
  cardExpanded: false,
};

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const user = useAuthenticatedUser();
  const valueInLs = window.localStorage.getItem("selectedTenant");
  const selectedTenant = valueInLs !== undefined ? JSON.parse(valueInLs) : null;

  // Get the query params from the URL
  const searchParams = new URLSearchParams(location.search);
  // Check if the query param for the error message exists
  const errorMessage = searchParams.get("error");

  if (!user?.login_id) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page

    // If there is an error message, send it along to the login page
    if (errorMessage) {
      return <Navigate to={`/login?error=${errorMessage}`} state={{ from: location }} replace />;
    } else {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  }

  const isCurrentPageCustomersPage = location.pathname === "/customers";
  // when OOTB user does not have selected customer/tenant, redirect to /customers
  if (
    // to avoid infinite redirects, proceed only if current page is not /customers page
    !isCurrentPageCustomersPage &&
    user?.tenant_id === MASTER_TENANT &&
    !selectedTenant
  ) {
    return <Navigate to="/customers" state={{ from: location }} replace />;
  }
  return <Outlet />;
};

const ShowOnboard = ({ children }) => {
  const user = useAuthenticatedUser();
  const isCurrentPageOnboardPage = location.pathname.includes("onboarding");

  if (!isCurrentPageOnboardPage && user?.show_onboarding && user?.tenant_id !== MASTER_TENANT) {
    return <Navigate to="/onboarding/select-datasources" replace />;
  }

  return children;
};

const RequireAdmin = ({ children }) => {
  const location = useLocation();
  const user = useAuthenticatedUser();

  if (user?.tenant_id !== MASTER_TENANT) {
    return <Navigate to={location.pathname} replace />;
  }

  return children;
};

function useBackgroundLocation() {
  const location = useLocation();
  // The `backgroundLocation` state is the location that we were at when one of
  // the modal links was clicked. If it's there, use it as the location for
  // the <Routes> so we show the page where click originated in the background, behind the modal.
  const { backgroundLocation, isOverlay } = location.state || {};
  // when user reloads page manually, isOverlay is set to false
  // so, when isOverlay is false (or undefined), we'll mount just regular route
  const shouldMountModalRoute = backgroundLocation && isOverlay;
  const routesLocation = shouldMountModalRoute ? backgroundLocation : location;

  return { shouldMountModalRoute, location: routesLocation };
}

const AppContainer = () => {
  const { shouldMountModalRoute, location } = useBackgroundLocation();
  const userLogin = useSelector((state) => state.userLogin);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Routes location={location}>
          <Route path="login" element={<LoginScreen />} />
          <Route path="signup" element={<SignupScreen />} />

          <Route path="/" element={<RequireAuth />}>
            <Route
              path="customers"
              element={
                <RequireAdmin>
                  <CustomersScreen />
                </RequireAdmin>
              }
            />

            <Route element={<AppLayout />}>
              {/* New Onboarding flow  */}
              <Route path="onboard">
                <Route path="create_tenant" element={<TenantDetailsScreen />} />
                <Route path="join_workspace" element={<JoinWorkspaceScreen />} />
                <Route path="user_persona" element={<PersonaCreationScreen />} />
                <Route path="app_installation" element={<AppInstallationScreen />} />
                <Route path="start_trial" element={<StartFreeTrial />} />
                <Route path="stripe_add_on" element={<StripeBillingScreen />} />
              </Route>

              <Route path="onboarding" element={<OnboardScreen />}>
                <Route index element={<SelectDataSources />} />
                <Route index path="select-datasources" element={<SelectDataSources />} />
                <Route path="csv" element={<CsvFileUpload />} />
                <Route path="connection/sync" element={<SyncingData />} />
                <Route path="configure-metrics" element={<ConfigureMetrics />} />
                <Route path="set-preferences" element={<SetPreferences />} />
                <Route path="finish" element={<FinishOnboarding />} />
              </Route>

              {/* This is a hack done as a part of DEV-5010. */}
              {userLogin.is_design_partner === false ? (
                <Route index element={<Navigate to="/events" replace />} />
              ) : (
                <Route index element={<DigestScreen />} />
              )}

              <Route path="feed" element={<FeedScreen />} />
              <Route path="events" element={<EventGroupScreen />} />
              <Route path="datasources" element={<DatasourcesStatusScreen />} />
              <Route path="metrics" element={<MetricListScreen />} />
              <Route path="preferences" element={<PreferenceScreen />} />
              <Route path="docs/api" element={<RedocScreen />} />
              <Route path="explorer" element={<DataExplorerScreen />} />
              <Route path="metrics/:metricId" element={<MetricDetailsScreen />} />
              <Route path="insights/:insightId" element={<SingleInsightScreen />} />
              <Route path="groups/:groupId" element={<GroupInsightScreen />} />
              <Route
                path={`insights/:id/${COLLABORATE}`}
                element={<InsightCollaborateScreen />}
              />
              <Route path={`groups/:id/${COLLABORATE}`} element={<GroupCollaborateScreen />} />
              <Route
                path={`events/groups/:eventGroupId/`}
                element={<EventGroupCollaborateScreen />}
              />
              <Route
                path="*"
                element={
                  <ScreenError
                    title={errorTitle.NOT_FOUND}
                    message={errorMessage.PAGE_NOT_FOUND}
                  />
                }
              />
            </Route>
          </Route>
        </Routes>
      </QueryClientProvider>

      {/**
       * This is necessary in order to render `Modal` component on top of the page that triggered modal open.
       * This will append routes that are matching clicked link to the DOM, but since we're rendering `Modal`,
       * it'll be visually rendered on top of the existing components that are rendered.
       */}
      {shouldMountModalRoute && (
        <Routes>
          <Route path="/" element={<RequireAuth />}>
            <Route
              path={`insights/:id/${COLLABORATE}`}
              element={
                <ModalRouteContainer>
                  <InsightCollaborateScreen />
                </ModalRouteContainer>
              }
            />
            <Route
              path={`groups/:id/${COLLABORATE}`}
              element={
                <ModalRouteContainer>
                  <GroupCollaborateScreen />
                </ModalRouteContainer>
              }
            />
            <Route
              path={`events/groups/:eventGroupId`}
              element={
                <ModalRouteContainer>
                  <EventGroupCollaborateScreen />
                </ModalRouteContainer>
              }
            />
          </Route>
        </Routes>
      )}
    </>
  );
};

let didInit = false;
function App() {
  // Inoke Google Tag Manager, with the GTM ID
  initializeGTM(gtmID);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.userLogin);

  if (!didInit) {
    didInit = true;
    dispatch(getIsUserLoggedIn());
  }

  const content = loading ? <LoaderInCenter /> : <AppContainer />;

  return (
    <SCThemeProvider theme={theme}>
      <ThemeProvider theme={muiTheme}>
        <GlobalStyles />

        <FeatureContext.Provider value={featureContext}>{content}</FeatureContext.Provider>
      </ThemeProvider>
    </SCThemeProvider>
  );
}

const exportApp = process.env.REACT_APP_SENTRY_DSN ? withProfiler(App) : App;
export default exportApp;
