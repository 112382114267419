import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import React, { useState } from "react";

const RenderRows = (rows) => {
  const { row, row_index } = rows;
  const [open, setOpen] = useState(false);

  // suppose the complete URL is
  // https://outoftheblue.ai/collections/pages
  // this utility will trim the base url and prepend it to ...
  // it will look like .../collections/pages
  const trimBaseUrl = (page_url) => {
    try {
      const parsedUrl = new URL(page_url);
      const path = parsedUrl.pathname; // Extracts the path part
      return `...${path}`;
    } catch (error) {
      return page_url; // In case of an invalid URL, return the original
    }
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { border: "none", ":hover": { cursor: "pointer" } } }}
        onClick={() => setOpen(!open)}
      >
        <TableCell align="left">{row_index + 1}</TableCell>
        <TableCell align="left">{row.name ? trimBaseUrl(row.name) : "-"}</TableCell>
        <TableCell align="right">{row?.number_of_errors ? row?.number_of_errors : "-"}</TableCell>
        <TableCell align="right">
          {row?.formatted_percentage_per_page_viewed
            ? row?.formatted_percentage_per_page_viewed
            : "-"}
        </TableCell>
        <TableCell align="right">
          {row?.formatted_percentage_overall_page_views
            ? row?.formatted_percentage_overall_page_views
            : "-"}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
const EventExtentDistributionTable = ({ pages }) => {
  return (
    <TableContainer component={Paper} style={{ overflow: "auto", maxHeight: 500 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="left" sx={{ width: "7%" }}>
              <Typography sx={(theme)=>({ fontSize: 14})}>Sr. No.</Typography>
            </TableCell>
            <TableCell align="left" sx={{ width: "25%" }}>
              <Typography sx={(theme)=>({ fontSize: 14})}>Name</Typography>
            </TableCell>
            <TableCell align="right" sx={{ width: "15%" }}>
              <Typography sx={(theme)=>({ fontSize: 14})}>Number of Errors</Typography>
            </TableCell>
            <TableCell align="right" sx={{ width: "15%" }}>
              <Stack align="center" justifyContent="right" direction="row" gap={1} alignItems="center">
                <Typography sx={(theme)=>({ fontSize: 14})}>% Of Error Page views</Typography>
                <Tooltip key="column_3"  title={"Percentage of occurrence of errors for this specific page"} placement="bottom-end">
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
              </Stack>
            </TableCell>
            <TableCell align="right" sx={{ width: "15%" }}>
            <Stack align="center" justifyContent="right" direction="row" gap={1}>
              <Typography sx={(theme)=>({ fontSize: 14})}>% of Total page views</Typography>
              <Tooltip key="column_4"  title={"Percentage of occurrence of errors for all page views"} placement="bottom-end">
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
              </Stack>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pages.map((row, index) => (
            <RenderRows key={index} row={row} row_index={index} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EventExtentDistributionTable;
